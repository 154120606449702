import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  useMediaQuery,
  Container,
  Stack,
  Button,
  Checkbox,
  IconButton,
  LinearProgress,
} from "@mui/material";
import CommonTable1 from "app/components/Tables/CommonTable1";
import CustomLoader from "app/components/common/CustomLoader";
import ChangeStatusDialog from "./ChangeStatusDialog";

import TableActionButtons from "app/components/common/TableActionButtons";
import AdmissionEnquiryFilterDialog from "app/components/common/Dialogs/AdmissionEnquiryFilterDialog";
import AddEnquiryForm2 from "./AddEnquiryForm2";
import ExportDialog from "app/components/common/exportDialog";
import {
  getAdmissionEnquiry,
  deleteAdmissionEnquiry,
} from "app/services/schoolService/admissionEnquiry.service";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import {
  selectActiveSession,
  selectedMediumSelector,
} from "app/modules/schools/selectors";
import { toast } from "react-toastify";
import { FormatFullName } from "app/utils/helper";
import DeleteDialog from "app/components/ConfirmationDialog/DeleteDialog";
import { useMutation } from "react-query";
import ExportButtonGroup from "app/components/common/ExportButtonGroup";
import dayjs from "dayjs";
import { Message, RemoveRedEye, WhatsApp } from "@mui/icons-material";
import ViewEnquiryForm from "./ViewEnquiryForm";

const AdmissionEnquiry = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [openCheckBox, setOpenCheckBox] = useState(false);

  const [state, setState] = useState({
    filterData: false,
    addDialog: false,
    editDialog: false,
    deleteDialog: false,
    changeStatusDialog: false,
    deleteId: "",
    editData: {},
    changeStatusData: {},
    allCheck: false,
    dataList: [],
    viewEnquiry: false,
  });
  const selectedSession = useSelector(selectActiveSession);
  const selectedMedium = useSelector(selectedMediumSelector);
  const {
    data: getAdmissionEnquiryData,
    isLoading: getAdmissionEnquiryLoading,
    refetch: getAdmissionEnquiryRefetch,
    isRefetching,
  } = useQuery({
    queryKey: [
      "getAdmissionEnquiry",
      state.fetch,
      selectedSession,
      selectedMedium,
    ],
    queryFn: () => {
      if (state.filterData) {
        return getAdmissionEnquiry({
          session: selectedSession,
          ...state.filterData,
        });
      } else {
        return getAdmissionEnquiry({
          session: selectedSession,
        });
      }
    },
    onSuccess: (success) => {
      setState((prev) => ({
        ...prev,
        dataList: success?.data?.list?.map((item) => ({
          ...item,
          checked: false,
        })),
      }));
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const { mutate: deleteMutate, isLoading: deleteLoading } = useMutation(
    deleteAdmissionEnquiry,
    {
      onSuccess: ({ data }) => {
        toast.success(data?.message);
        setState((prev) => ({
          ...prev,
          deleteId: "",
          deleteDialog: false,
        }));
        getAdmissionEnquiryRefetch();
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  let header = [
    "Form Number",
    "ClassName",
    "Student Name",
    "Mobile Number",
    "Form Amount",
    "Payment Mode",
    "Enquiry Date",
    "Enquiry Status",
    "Payment Status",
    "Category",
    "Community",
    "Address",
    "Gender",
    "Father Name",
    "Father Annual Income",
    "Father Mobile Number",
    "Father Occupation",
    "Mother Name",
    "Mother Annual Income",
    "Mother Mobile Number",
    "Mother Occupation",
    "Guardian Name",
    "Guardian Annual Income",
    "Guardian Mobile Number",
    "Guardian Occupation",
    "Guardian Relation",
  ];
  let exportArray = getAdmissionEnquiryData?.data?.list?.map((item) => {
    return {
      "Form Number": item?.formNumber ?? "--",
      ClassName: item?.className ?? "--",
      "Student Name": FormatFullName(
        item?.firstName,
        item?.middleName,
        item?.lastName
      ),
      "Mobile Number": item?.mobileNumber ?? "--",
      "Form Amount": item?.formAmount ?? "--",
      "Payment Mode": item?.paymentMode ?? "--",
      "Enquiry Status": item?.enquiryStatus ?? "--",
      "Payment Status": item?.paymentStatus ?? "--",
      "Enquiry Date": dayjs(item?.enquiryDate).format("DD MMM,YYYY") ?? "--",
      Category: item?.category ?? "--",
      Community: item?.community ?? "--",
      Address:
        `${item?.currentAddress?.address ?? ""} ${
          item?.currentAddress?.dist ?? ""
        } ${item?.currentAddress?.state ?? ""} ${
          item?.currentAddress?.pinCode ?? ""
        }` ?? "--",

      Gender: item?.gender,
      "Father Name": FormatFullName(
        item?.fatherInfo?.firstName,
        item?.fatherInfo?.middleName,
        item?.fatherInfo?.lastName
      ),
      "Father Annual Income": item?.fatherInfo?.annualIncome ?? "--",
      "Father Mobile Number": item?.fatherInfo?.mobileNumber ?? "--",
      "Father Occupation": item?.fatherInfo?.occupation ?? "--",
      "Mother Name": FormatFullName(
        item?.motherInfo?.firstName,
        item?.motherInfo?.middleName,
        item?.motherInfo?.lastName
      ),
      "Mother Annual Income": item?.motherInfo?.annualIncome ?? "--",
      "Mother Mobile Number": item?.motherInfo?.mobileNumber ?? "--",
      "Mother Occupation": item?.motherInfo?.occupation ?? "--",
      "Guardian Name": FormatFullName(
        item?.guardianInfo?.firstName,
        item?.guardianInfo?.middleName,
        item?.guardianInfo?.lastName
      ),
      "Guardian Annual Income": item?.guardianInfo?.annualIncome ?? "--",
      "Guardian Mobile Number": item?.guardianInfo?.mobileNumber ?? "--",
      "Guardian Occupation": item?.guardianInfo?.occupation ?? "--",
      "Guardian Relation": item?.guardianInfo?.relation ?? "--",
    };
  });

  const columns = [
    {
      Header: ({ column }) => (
        <Stack direction={"row"} alignItems={"center"}>
          <Typography>Sr No.</Typography>
          <Checkbox
            color="secondary"
            onChange={(e) =>
              setState((prev) => ({ ...prev, allCheck: !prev.allCheck }))
            }
            checked={state.allCheck}
          />
        </Stack>
      ),
      header: "Sr.No",
      Cell: ({ cell: { row } }) => (
        <Stack direction={"row"} alignItems={"center"}>
          <Typography>
            {parseInt(row.id) < 9
              ? `0${parseInt(row.id) + 1}`
              : parseInt(row.id) + 1}
          </Typography>
          <Checkbox
            color="secondary"
            onChange={(e) => {
              row.original.checked = !row?.original?.checked;
              setState((prev) => ({ ...prev }));
            }}
            checked={row.original.checked ?? false}
          />
        </Stack>
      ),
      size: 30,
    },
    {
      accessorKey: "source",
      header: "Source",
      size: 30,
    },
    {
      accessorKey: "formNumber",
      header: "Form Number",
      size: 30,
    },
    {
      accessorKey: "firstName",
      Header: () => (
        <>
          <Typography>Student</Typography>
          <Typography sx={{ opacity: 0.8 }}>Father</Typography>
        </>
      ),
      header: "Student Name",
      Cell: ({ cell }) => (
        <>
          <Typography>
            {FormatFullName(
              cell?.row?.original?.firstName,
              cell?.row?.original?.middleName,
              cell?.row?.original?.lastName
            )}
          </Typography>
          <Typography sx={{ opacity: 0.8 }}>
            {FormatFullName(
              cell?.row?.original?.fatherInfo?.firstName,
              cell?.row?.original?.fatherInfo?.middleName,
              cell?.row?.original?.fatherInfo?.lastName
            )}
          </Typography>
        </>
      ),
      size: 90,
    },
    {
      accessorKey: "gender",
      header: "Gender",
      size: 20,
    },
    {
      accessorKey: "className",
      header: "Class-Stream",
      Cell: ({ cell }) => (
        <Typography>
          {cell?.row?.original?.className} - {cell?.row?.original?.stream}
        </Typography>
      ),
      size: 50,
    },
    {
      accessorKey: "mobileNumber",
      Header: () => (
        <>
          <Typography>Mobile</Typography>
          <Typography>WhatsApp</Typography>
        </>
      ),
      header: "Mobile",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => (
        <>
          <Typography>{original?.mobileNumber}</Typography>
          <Typography>{original?.whatsAppNumber}</Typography>
        </>
      ),
      size: 60,
    },
    {
      accessorKey: "followUp",
      header: "Follow Up",
      size: 30,
    },
    {
      accessorKey: "counselor",
      header: "Counselor",
      size: 30,
    },
    {
      accessorKey: "enquiryStatus",
      header: "Enquiry Status",
      Cell: ({ cell }) => {
        return (
          <Typography
            sx={{
              color:
                cell?.row?.original?.enquiryStatus === "Active"
                  ? "#FBB122"
                  : cell?.row?.original?.enquiryStatus === "Won"
                  ? "green"
                  : cell?.row?.original?.enquiryStatus === "Lost"
                  ? "red"
                  : "black",
              textDecoration: "underline",
              fontStyle: "italic",
              cursor: "pointer",
            }}
            onClick={() => {
              setState((prev) => ({
                ...prev,
                changeStatusDialog: true,
                changeStatusData: cell?.row?.original,
              }));
            }}
          >
            {cell?.row?.original?.enquiryStatus}
          </Typography>
        );
      },
      size: 50,
    },
    {
      accessorKey: "paymentStatus",
      header: "Payment Status",
      size: 20,
    },
    {
      accessorKey: "whatsApp",
      header: "WhatsApp",
      size: 20,
    },
    {
      accessorKey: "textSms",
      header: "Text SMS",
      size: 20,
    },
    {
      accessorKey: "call",
      header: "Call",
      size: 20,
    },
    {
      accessorKey: "visits",
      header: "Visits",
      size: 20,
    },
    {
      header: "Action",
      maxSize: 70,
      Cell: ({ cell }) => {
        return (
          <Stack direction={"row"} alignItems={"center"}>
            <IconButton size="small">
              <WhatsApp />
            </IconButton>
            <IconButton size="small">
              <Message />
            </IconButton>
            <IconButton
              size="small"
              onClick={() =>
                setState((prev) => ({ ...prev, viewEnquiry: true }))
              }
            >
              <RemoveRedEye />
            </IconButton>
          </Stack>
        );
      },
    },
  ];
  const handleMutate = (payload) => {
    setState((prev) => ({
      ...prev,
      filterData: payload,
      fetch: !prev.fetch,
    }));
  };
  const handleEdit = (data) => {
    setState((prev) => ({
      ...prev,
      editDialog: true,
      editData: data,
    }));
  };

  const handleCloseDeleteDialog = () => {
    setState((prev) => ({
      ...prev,
      deleteDialog: false,
      deleteId: "",
    }));
  };
  const handleCloseChangeStatusDialog = () => {
    setState((prev) => ({
      ...prev,
      changeStatusDialog: false,
    }));
  };

  const handleDelete = (data) => {
    setState((prev) => ({
      ...prev,
      deleteDialog: true,
      deleteId: data?._id,
    }));
  };
  const handledeleteForm = () => {
    deleteMutate(state?.deleteId);
  };
  useEffect(() => {
    if (state.dataList?.length > 0) {
      if (state.allCheck) {
        setState((prev) => ({
          ...prev,
          dataList: prev.dataList?.map((item) => ({ ...item, checked: true })),
        }));
      } else {
        setState((prev) => ({
          ...prev,
          dataList: prev.dataList?.map((item) => ({ ...item, checked: false })),
        }));
      }
    }
  }, [state.allCheck]);

  return (
    <>
      {/* view Eqnuiry form */}
      <ViewEnquiryForm
        open={state.viewEnquiry}
        onClose={() => setState((prev) => ({ ...prev, viewEnquiry: false }))}
      />
      {/* add form */}
      <AddEnquiryForm2
        add={state.addDialog}
        edit={state.editDialog}
        editData={state.editData}
        refetch={() => getAdmissionEnquiryRefetch()}
        handleClose={() =>
          setState((prev) => ({
            ...prev,
            editData: {},
            editDialog: false,
            addDialog: false,
          }))
        }
      />

      <ChangeStatusDialog
        open={state.changeStatusDialog}
        handleClose={handleCloseChangeStatusDialog}
        data={state.changeStatusData}
        refetch={() => getAdmissionEnquiryRefetch()}
      />

      {/* Export  */}
      <ExportDialog
        open={openCheckBox}
        handleClose={() => setOpenCheckBox(false)}
        header={header}
        exportArray={exportArray}
        fileName="Admission Enquiry"
      />

      {/* Delete Dialog Confirmation */}
      <DeleteDialog
        open={state.deleteDialog}
        handleClose={handleCloseDeleteDialog}
        handleDelete={handledeleteForm}
        loading={deleteLoading}
      />

      <Container maxWidth="xl">
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{
            mb: 2,
          }}
        >
          <AdmissionEnquiryFilterDialog
            mutate={handleMutate}
            loading={() => isRefetching()}
          />
          <Stack
            direction="row"
            spacing={2}
            sx={{ justifyContent: "center", alignItems: "center" }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 600,
                // mb: 1.5,
              }}
            >
              Total Student :{" "}
              <span style={{ fontSize: "18px" }}>
                {getAdmissionEnquiryData?.data?.list?.length || 0}
              </span>
            </Typography>
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              onClick={() => {
                setState((prev) => ({
                  ...prev,
                  addDialog: true,
                }));
              }}
            >
              + ADD NEW
            </Button>
            <ExportButtonGroup
              showPdf={false}
              handleClickExcel={() => setOpenCheckBox(true)}
            />
          </Stack>
        </Stack>

        {(getAdmissionEnquiryLoading || isRefetching || deleteLoading) && (
          <LinearProgress />
        )}
        <CommonTable1
          columns={columns}
          data={state.dataList || []}
          maxHeight={"63vh"}
        />
      </Container>
    </>
  );
};

export default AdmissionEnquiry;
