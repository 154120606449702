import React, { useState, useRef } from "react";
import CommonDialog from "../CommonDialog";
import {
  Container,
  Stack,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormGroup,
  FormControlLabel,
  Checkbox,
  DialogContent,
  DialogActions,
  Grid,
  Box,
  Typography,
  LinearProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useQuery } from "react-query";
import { getOnlinePayrollForPrint } from "app/services/hr";
import { toast } from "react-toastify";
import { FormatFullName } from "app/utils/helper";
import { useReactToPrint } from "react-to-print"; // Import the hook
import SchoolPrintHeaderForBulkPrint from "../../SchoolPrintHeaderForBulkPrint";

const TableFooterTotals = ({ data, keys, colSpan, selectedHeaders }) => {
  let selectedTotal = {
    grossSalary: selectedHeaders.includes("Gross Salary"),
    deductionSalary: selectedHeaders.includes("Total Deduction"),
    netSalary: selectedHeaders.includes("Net Salary"),
  };

  const calculateTotal = (key) => {
    return data?.reduce((acc, curr) => acc + (curr[key] || 0), 0);
  };

  return (
    <Grid container>
      <Grid item xs={colSpan}>
        <Typography
          variant="body1"
          sx={{ fontWeight: "bold", fontSize: "12px" }}
        >
          Total
        </Typography>
      </Grid>
      {
        // Render a cell for each key
        keys.map(
          (key, idx) =>
            selectedTotal[key] && (
              <Grid item xs={colSpan} key={idx}>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "bold", fontSize: "12px" }}
                >
                  {key === "grossSalary"
                    ? "Total Gross Salary"
                    : key === "deductionSalary"
                    ? "Total Deduction"
                    : "Total Net Salary"}{" "}
                  : {calculateTotal(key)}
                </Typography>
              </Grid>
            )
        )
      }
    </Grid>
  );
};

export const TableSignFooter = ({}) => {
  return (
    <Grid container>
      <Grid item xs={4}>
        <Typography
          variant="body1"
          sx={{ fontWeight: "bold", fontSize: "12px" }}
        >
          Prepared By
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography
          variant="body1"
          sx={{ fontWeight: "bold", fontSize: "12px" }}
        >
          Checked By
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography
          variant="body1"
          sx={{ fontWeight: "bold", fontSize: "12px" }}
        >
          Approved By
        </Typography>
      </Grid>
    </Grid>
  );
};

const mapRowData = (row, header, index) => {
  switch (header) {
    case "#":
      return index < 9 ? `0${index + 1}` : index + 1;
    case "Employee Name":
      return FormatFullName(
        row?.staffDetail?.firstName,
        row?.staffDetail?.middleName,
        row?.staffDetail?.lastName
      );
    case "Account Number":
      return row?.staffDetail?.bankAccountNumber ?? "--";
    case "IFSC Code":
      return row?.staffDetail?.ifscCode ?? "--";
    case "Gross Salary":
      return row?.grossSalary ?? "0";
    case "Total Deduction":
      return row?.deductionSalary ?? "0";
    case "Net Salary":
      return row?.netSalary ?? "0";
    default:
      return "--"; // fallback in case there's no matching header
  }
};

const TablePreview = React.forwardRef(
  ({ data, selectedHeaders, filterParams = {} }, ref) => {
    return (
      <Box
        ref={ref} // Assign the ref to the Box (the element that will be printed)
        sx={{
          padding: "20px",
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SchoolPrintHeaderForBulkPrint />
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                fontSize: "16px",
                textAlign: "center",
                textDecoration: "underline",
              }}
            >
              Payroll Report for {filterParams?.month ?? "--"}{" "}
              {filterParams?.year ?? "--"}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Table
              sx={{
                border: "1px solid #ccc",
                width: "100%",
                borderCollapse: "collapse",
              }}
            >
              <TableHead
                sx={{
                  backgroundColor: "#B6C8D6",
                  color: "#000",
                  fontWeight: "bold",
                  overflow: "hidden",
                  mt: 2,
                }}
              >
                <TableRow>
                  {/* Render table headers */}
                  {selectedHeaders?.map((header, idx) => (
                    <TableCell
                      key={idx}
                      sx={{
                        padding: "10px",
                        fontWeight: "bold",
                        textAlign: "center",
                        borderBottom: "1px solid #ccc",
                      }}
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {/* Render table rows */}
                {data?.map((row, idx) => (
                  <TableRow
                    key={idx}
                    sx={{
                      "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" },
                    }}
                  >
                    {selectedHeaders?.map((header, index) => (
                      <TableCell
                        key={index}
                        sx={{
                          padding: "10px",
                          textAlign: "center",
                          borderBottom: "1px solid #ccc",
                        }}
                      >
                        {mapRowData(row, header, idx)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            {/* Table Footer */}
            <TableFooterTotals
              data={data}
              keys={["grossSalary", "deductionSalary", "netSalary"]}
              colSpan={3}
              selectedHeaders={selectedHeaders}
            />
          </Grid>

          <Grid item xs={12}>
            <TableSignFooter />
          </Grid>
        </Grid>
      </Box>
    );
  }
);

export const HeaderSelection = ({
  headers,
  selectedHeaders,
  handleHeaderChange,
}) => {
  return (
    <FormGroup row>
      {headers.map((header, idx) => (
        <FormControlLabel
          key={idx}
          control={
            <Checkbox
              checked={selectedHeaders.includes(header)}
              onChange={() => handleHeaderChange(header)}
            />
          }
          label={header}
        />
      ))}
    </FormGroup>
  );
};

const BulkPrintDialog = ({
  open = false,
  onClose = () => {},
  filterParams = {},
}) => {
  const {
    isLoading: getOnlinePayrollForPrintLoading,
    isFetching: getOnlinePayrollForPrintFetching,
    refetch: getOnlinePayrollForPrintRefetch,
    data: getOnlinePayrollForPrintData,
  } = useQuery({
    queryKey: ["getPayrollData", filterParams],
    queryFn: () => getOnlinePayrollForPrint(filterParams),
    onSuccess: (res) => {},
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: filterParams && open ? true : false,
  });

  // List of all possible headers
  const headers = [
    "#",
    "Employee Name",
    "Account Number",
    "IFSC Code",
    "Gross Salary",
    "Total Deduction",
    "Net Salary",
  ];

  // State for selected headers
  const [selectedHeaders, setSelectedHeaders] = useState(headers);

  // Handle header selection
  const handleHeaderChange = (header) => {
    setSelectedHeaders((prev) =>
      prev.includes(header)
        ? prev.filter((h) => h !== header)
        : [...prev, header]
    );
  };

  // Create a reference to the TablePreview component
  const tablePreviewRef = useRef();

  // Use the useReactToPrint hook
  const handlePrint = useReactToPrint({
    content: () => tablePreviewRef.current, // Refers to the TablePreview component
  });

  return (
    <CommonDialog
      open={open}
      onClose={onClose}
      title="Bulk Print"
      minWidth={"70%"}
    >
      <DialogContent>
        <Container maxWidth={"lg"}>
          {/* Header Selection Component */}
          <HeaderSelection
            headers={headers}
            selectedHeaders={selectedHeaders}
            handleHeaderChange={handleHeaderChange}
          />

          {
            // Show loading spinner if data is loading
            getOnlinePayrollForPrintLoading ||
            getOnlinePayrollForPrintFetching ? (
              <LinearProgress
                sx={{ width: "100%", borderRadius: "4px", mt: 2 }}
              />
            ) : null
          }

          {/* Table Preview */}
          <TablePreview
            filterParams={filterParams}
            ref={tablePreviewRef} // Pass the ref to TablePreview
            data={getOnlinePayrollForPrintData?.data?.list ?? []}
            selectedHeaders={selectedHeaders}
          />
        </Container>
      </DialogContent>
      <DialogActions>
        {/* Action Buttons */}
        <Stack spacing={2} direction={"row"} alignItems={"flex-end"} mt={2}>
          <LoadingButton
            variant="contained"
            color="primary"
            size="large"
            onClick={handlePrint}
          >
            Print PDF
          </LoadingButton>
        </Stack>
      </DialogActions>
    </CommonDialog>
  );
};

export default BulkPrintDialog;
