import React, { useEffect, useState } from "react";
import CommonDialog from "../CommonDialog";
import { Controller, useForm } from "react-hook-form";
import {
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import TextFieldLabel from "../../TextFieldLabel";
import {
  CustomTextArea,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import { DatePicker } from "@mui/x-date-pickers";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import {
  createAdmissionEnquiryThread,
  editAdmissionEnquiryThread,
} from "app/services/schoolService/admissionEnquiry.service";

const AddNewThreadDialog = ({
  open,
  onClose,
  refetch = () => {},
  edit = false,
  selectedThread,
  admissionEnquiryDocId,
}) => {
  const [state, setState] = useState({
    nextFollowUpDate: new Date(),
  });

  const { control, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      homeVisit: false,
      teleCall: true,
      whatDiscussed: "",
      nextFollowUpDate: new Date(),
      whatToBeDiscussed: "",
    },
  });

  const handleCloseDialog = async () => {
    reset({
      homeVisit: false,
      teleCall: false,
      whatDiscussed: "",
      nextFollowUpDate: new Date(),
      whatToBeDiscussed: "",
    });
    setState((prev) => ({
      ...prev,
      nextFollowUpDate: new Date(),
    }));
    onClose();
  };

  const {
    mutate: createAdmissionEnquiryThreadMutate,
    isLoading: createAdmissionEnquiryThreadLoading,
  } = useMutation(createAdmissionEnquiryThread, {
    onSuccess: async (success) => {
      toast.success(success?.data?.message);
      await handleCloseDialog();
      refetch();
    },
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
  });
  const {
    mutate: editAdmissionEnquiryThreadMutate,
    isLoading: editAdmissionEnquiryThreadLoading,
  } = useMutation(editAdmissionEnquiryThread, {
    onSuccess: async ({ data }) => {
      toast.success(data?.message);
      refetch();
      await handleCloseDialog();
    },
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
  });

  const handleFormSubmt = (data) => {
    let payload = {
      admissionEnquiryDocId: admissionEnquiryDocId,
      communicationType: data?.homeVisit ? "Home Visit" : "Tele Call",
      whatDiscussed: data?.whatDiscussed,
      nextFollowUpDate: data?.nextFollowUpDate,
      toBeDiscussed: data?.whatToBeDiscussed,
    };

    if (edit) {
      payload = {
        ...payload,
        enquiryThreadDocId: selectedThread?._id,
      };
      editAdmissionEnquiryThreadMutate(payload);
    } else {
      createAdmissionEnquiryThreadMutate(payload);
    }
  };

  useEffect(() => {
    if (edit) {
      reset({
        homeVisit: selectedThread?.communicationType === "Home Visit",
        teleCall: selectedThread?.communicationType === "Tele Call",
        whatDiscussed: selectedThread?.whatDiscussed,
        nextFollowUpDate: new Date(selectedThread?.nextFollowUpDate),
        whatToBeDiscussed: selectedThread?.toBeDiscussed,
      });
      setState((prev) => ({
        ...prev,
        nextFollowUpDate: new Date(selectedThread?.nextFollowUpDate),
      }));
    }
  }, [edit, selectedThread]);

  return (
    <CommonDialog
      open={open}
      onClose={handleCloseDialog}
      title="Add More Communication Thread"
      minWidth="500px"
    >
      <DialogContent>
        <form onSubmit={handleSubmit(handleFormSubmt)} id="add_thread_form">
          <Grid container spacing={1}>
            <Grid item xs={12} md={3}>
              <Controller
                control={control}
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                }) => (
                  <>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          checked={value}
                          onChange={(e) => {
                            setValue("homeVisit", e.target.checked);
                            if (e.target.checked) {
                              setValue("teleCall", false);
                            }
                          }}
                        />
                      }
                      label="Home Visit"
                      componentsProps={{
                        typography: {
                          fontSize: "14px",
                          fontWeight: 600,
                        },
                      }}
                    />
                  </>
                )}
                name="homeVisit"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Controller
                control={control}
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="secondary"
                        checked={value}
                        onChange={(e) => {
                          setValue("teleCall", e.target.checked);
                          if (e.target.checked) {
                            setValue("homeVisit", false);
                          }
                        }}
                      />
                    }
                    label="Tele Call"
                    componentsProps={{
                      typography: {
                        fontSize: "14px",
                        fontWeight: 600,
                      },
                    }}
                  />
                )}
                name="teleCall"
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldLabel title={"What Discussed ?"} />
              <Controller
                control={control}
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                }) => (
                  <CustomTextArea
                    onBlur={onBlur}
                    onChange={onChange}
                    placeholder="Type discussed text..."
                    value={value}
                    error={!!error}
                  />
                )}
                name="whatDiscussed"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldLabel title="Next Follow Up Date" required />
              <DatePicker
                inputFormat="DD-MM-YYYY"
                onChange={(e) => {
                  setValue("nextFollowUpDate", e);
                  setState((prev) => ({
                    ...prev,
                    nextFollowUpDate: e,
                  }));
                }}
                value={state.nextFollowUpDate}
                minDate={new Date()}
                renderInput={(params) => (
                  <CustomTextField {...params} size="small" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldLabel title={"What to be Discussed ?"} />
              <Controller
                control={control}
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                }) => (
                  <CustomTextArea
                    onBlur={onBlur}
                    onChange={onChange}
                    placeholder="Type discussing text..."
                    value={value}
                    error={!!error}
                  />
                )}
                name="whatToBeDiscussed"
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          type="submit"
          color="secondary"
          form="add_thread_form"
          variant="contained"
          size="large"
        >
          {edit
            ? editAdmissionEnquiryThreadLoading
              ? "Updating..."
              : "Update"
            : createAdmissionEnquiryThreadLoading
            ? "Creating..."
            : "Create"}
        </LoadingButton>
      </DialogActions>
    </CommonDialog>
  );
};

export default AddNewThreadDialog;
