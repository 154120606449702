import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getClassDropdown } from "app/services/student-management";
import { useSelector } from "react-redux";
import {
  selectActiveSession,
  selectSelectedSchoolId,
  selectedMediumSelector,
} from "app/modules/schools/selectors";
import { toast } from "react-toastify";
import FilterButton from "../FilterButton";
import CommonFilterDialog from "./CommonFilterDialog";
import {
  Checkbox,
  DialogContent,
  FormControlLabel,
  MenuItem,
  Grid,
  Box,
  LinearProgress,
  Stack,
} from "@mui/material";
import {
  CustomSelectBox,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import TextFieldLabel from "../TextFieldLabel";
import { DatePicker } from "@mui/x-date-pickers";
import ByClassFilter from "app/components/commonSeperateMultipleFilter/ByClassFilter";
import {
  getCounselorDropdownList,
  getDistinctSources,
  getDistinctStatus,
  getEnquiryCreatedByList,
  getSourceList,
  getStatusList,
} from "app/services/schoolService/admissionEnquiry.service";
import { StaffCardDropDown } from "../StaffCardDropDown";
import Chip from "../CustomChip";
import { FormatFullName } from "app/utils/helper";

const CheckBoxComponent = ({
  onChange = () => {},
  checked,
  label = "Label",
}) => {
  return (
    <FormControlLabel
      checked={checked}
      onChange={onChange}
      control={<Checkbox color="secondary" />}
      label={label}
      componentsProps={{
        typography: {
          sx: { fontSize: "13px", fontWeight: 600 },
        },
      }}
    />
  );
};

const AdmissionEnquiryFilterDialog = ({
  mutate = () => {},
  loading = () => {},
  forVisualsPage = false,
  counselorDocId = "",
}) => {
  const selectedMedium = useSelector(selectedMediumSelector);
  const selectedSchoolId = useSelector(selectSelectedSchoolId);
  const selectedSession = useSelector(selectActiveSession);
  const [classOptions, setClassOptions] = useState([]);
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [classListToMutate, setClassListToMutate] = useState([]);

  const [state, setState] = useState({
    dialog: false,
    createdFrom: new Date(),
    createdTo: new Date(),
    enquiryFrom: new Date(),
    enquiryTo: new Date(),
    admissionProbabilityFrom: 0,
    admissionProbabilityTo: 100,
    paymentStatus: "",
    enquiryStatus: "",
    statusList: [],
    reqEnquiryStatusList: [],
    status: "",
    nextFollowUpFrom: new Date(),
    nextFollowUpTo: new Date(),
    // createdByDocId: "",
    reqCreatedByDocIdList: [],
    reqAssignedToDocIdList: [],
    // assignedToDocId: "",
    createdByList: [],
    assignedToList: [],
    counselDataList: [],
    sourcesData: [],
    selectedSource: "",
    communityData: [
      "Hindu",
      "Muslim",
      "Christian",
      "Sikh",
      "Buddhist",
      "Jain",
      "Others",
    ],
    selectedCommunity: "",
    livingTypeData: ["Hostellor", "Day Scholar"],
    selectedLivingType: "",
  });
  const [filters, setFilters] = useState({
    class: {
      classDocId: "",
      section: "",
    },
  });
  const [checkboxes, setCheckboxes] = useState({
    class: false,
    createdBy: false,
    assignedTo: false,
    createdOn: false,
    enquiryOn: false,
    paymentStatus: false,
    enquiryStatus: false,
    admissionProbability: false,
    nextFollowUp: false,
    source: false,
    community: false,
    livingType: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      if (counselorDocId) {
        setState((prev) => ({
          ...prev,
          reqAssignedToDocIdList: [counselorDocId],
        }));
        setCheckboxes((prev) => ({
          ...prev,
          assignedTo: true,
        }));
      }
    };
    fetchData();
  }, [counselorDocId]);

  const handleCloseFilterDialog = () => {
    setState((prev) => ({ ...prev, dialog: false }));
  };
  const handleView = () => {
    handleCloseFilterDialog();
    const body = {
      ...(checkboxes.class && {
        ...(classListToMutate.length > 0 && {
          classList: JSON.stringify(classListToMutate),
        }),
      }),
      ...(checkboxes.paymentStatus && {
        paymentStatus: state.paymentStatus,
      }),
      ...(checkboxes.enquiryStatus && {
        statusList: JSON.stringify(state?.reqEnquiryStatusList),
      }),
      ...(checkboxes.createdOn && {
        createdFrom: new Date(state.createdFrom),
        createdTo: new Date(state.createdTo),
      }),
      ...(checkboxes.enquiryOn && {
        enquiryFrom: new Date(state.enquiryFrom),
        enquiryTo: new Date(state.enquiryTo),
      }),
      ...(checkboxes.admissionProbability && {
        admissionProbabilityFrom: state.admissionProbabilityFrom,
        admissionProbabilityTo: state.admissionProbabilityTo,
      }),
      ...(checkboxes.nextFollowUp && {
        nextFollowUpFrom: new Date(state.nextFollowUpFrom),
        nextFollowUpTo: new Date(state.nextFollowUpTo),
      }),
      ...(checkboxes.createdBy && {
        createdByList: JSON.stringify(state?.reqCreatedByDocIdList),
      }),
      ...(checkboxes.assignedTo && {
        assignedToList: JSON.stringify(state?.reqAssignedToDocIdList),
      }),
      ...(checkboxes.source && {
        source: state.selectedSource,
      }),
      ...(checkboxes.community && {
        community: state.selectedCommunity,
      }),
      ...(checkboxes.livingType && {
        livingType: state.selectedLivingType,
      }),
    };
    mutate(body);
  };
  const {
    isLoading: getClassDropdownLoading,
    isFetching: getClassDropdownFetching,
  } = useQuery({
    queryKey: ["getClassDropdown", selectedSchoolId, selectedMedium],
    queryFn: () => getClassDropdown(selectedSchoolId, selectedMedium),
    onSuccess: (res) => {
      setClassOptions(res?.data?.list);
    },
    enabled: checkboxes.class ? true : false,
  });

  const { isFetching: getDistinctStatusFetching } = useQuery({
    queryKey: ["getStatusList"],
    queryFn: () => getStatusList(),
    onSuccess: (success) => {
      setState((prev) => ({
        ...prev,
        statusList: success?.data?.list ?? [],
      }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message ?? "Something went wrong");
    },
    enabled: checkboxes.enquiryStatus ? true : false,
  });

  const {
    loading: getEnquiryCreatedByListLoading,
    isFetching: getEnquiryCreatedByListFetching,
  } = useQuery({
    queryKey: ["getEnquiryCreatedByList"],
    queryFn: () => getEnquiryCreatedByList(),
    onSuccess: (success) => {
      setState((prev) => ({
        ...prev,
        createdByList: success?.data?.list ?? [],
      }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message ?? "Something went wrong");
    },
    enabled: checkboxes.createdBy ? true : false,
  });

  const {
    loading: getCounselorDropdownListLoading,
    isFetching: getCounselorDropdownListFetching,
    refetch: getCounselorDropdownListRefetch,
  } = useQuery({
    queryKey: ["getCounselorDropdownList_Filter"],
    queryFn: () =>
      getCounselorDropdownList({
        session: selectedSession,
      }),
    onSuccess: (success) => {
      setState((prev) => ({
        ...prev,
        assignedToList: success?.data?.list ?? [],
      }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message ?? "Something went wrong");
    },
    enabled: checkboxes.assignedTo ? true : false,
  });

  const {
    isLoading: getDistinctSourcesLoading,
    isFetching: getDistinctSourcesFetching,
  } = useQuery({
    queryKey: ["getSourceList"],
    queryFn: () => getSourceList(),

    onSuccess: (success) => {
      setState((prev) => ({ ...prev, sourcesData: success?.data?.list ?? [] }));
    },
    enabled: checkboxes.source ? true : false,
  });
  useEffect(() => {
    const filteredList = selectedClasses?.map(({ classDocId, section }) => ({
      classDocId,
      section,
    }));
    setClassListToMutate(filteredList);
  }, [selectedClasses]);

  return (
    <>
      <FilterButton
        hanldeClick={() => setState((prev) => ({ ...prev, dialog: true }))}
        count={Object.values(checkboxes).filter((item) => item === true).length}
      />
      <CommonFilterDialog
        open={state.dialog}
        onClose={handleCloseFilterDialog}
        handleSaveAndView={handleView}
        minWidth="500px"
      >
        <DialogContent>
          {getClassDropdownFetching ||
          getDistinctStatusFetching ||
          getEnquiryCreatedByListLoading ||
          getDistinctSourcesFetching ||
          getDistinctSourcesLoading ||
          getClassDropdownLoading ||
          getEnquiryCreatedByListFetching ||
          getCounselorDropdownListLoading ||
          getCounselorDropdownListFetching ? (
            <LinearProgress
              sx={{
                width: "100%",
              }}
            />
          ) : null}
          {/* Class  */}
          {!forVisualsPage && (
            <Box>
              <CheckBoxComponent
                checked={checkboxes.class}
                onChange={(e) => {
                  setFilters((prev) => ({
                    ...prev,
                    class: {
                      ...prev.class,
                      classDocId: "",
                      section: "",
                    },
                  }));
                  setCheckboxes((prev) => ({
                    ...prev,
                    class: !prev.class,
                  }));
                }}
                label="Class Info"
              />
              {checkboxes.class && (
                <ByClassFilter
                  prevClassList={selectedClasses}
                  setSelectedClasses={setSelectedClasses}
                  // onClassListChange={handleClassListChange}
                />
              )}
            </Box>
          )}
          {/*created By  */}
          {!forVisualsPage && (
            <Box>
              <CheckBoxComponent
                checked={checkboxes.createdBy}
                onChange={(e) => {
                  setCheckboxes((prev) => ({
                    ...prev,
                    createdBy: !prev.createdBy,
                  }));
                }}
                label="Created By"
              />
              {checkboxes.createdBy && (
                <Grid container spacing={1.5}>
                  <Grid xs={12} item>
                    <TextFieldLabel title="Created By" />
                    <CustomSelectBox
                      displayEmpty
                      size="small"
                      fullWidth
                      multiple
                      value={state.reqCreatedByDocIdList}
                      renderValue={() => {
                        if (state.reqCreatedByDocIdList.length === 0)
                          return "Select";
                        return state.createdByList
                          ?.filter((item) =>
                            state.reqCreatedByDocIdList.includes(
                              item?.staffDocId
                            )
                          )
                          ?.map(
                            (item) =>
                              FormatFullName(
                                item?.firstName,
                                item?.middleName,
                                item?.lastName
                              ) ?? item?.name
                          )
                          ?.join(", ");
                      }}
                    >
                      <MenuItem disabled value="">
                        Select
                      </MenuItem>
                      {state?.createdByList?.map((item) => (
                        <Box key={item?.staffDocId} sx={{ px: 1 }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="secondary"
                                checked={state.reqCreatedByDocIdList.includes(
                                  item?.staffDocId
                                )}
                                onChange={() => {
                                  const newCreatedByList =
                                    state.reqCreatedByDocIdList.includes(
                                      item?.staffDocId
                                    )
                                      ? state.reqCreatedByDocIdList.filter(
                                          (docId) => docId !== item?.staffDocId
                                        )
                                      : [
                                          ...state.reqCreatedByDocIdList,
                                          item?.staffDocId,
                                        ];
                                  setState((prev) => ({
                                    ...prev,
                                    reqCreatedByDocIdList: newCreatedByList,
                                  }));
                                }}
                              />
                            }
                            label={<StaffCardDropDown item={item} />}
                          />
                        </Box>
                      ))}
                    </CustomSelectBox>

                    {state?.reqCreatedByDocIdList?.length > 0 && (
                      <Stack
                        spacing={1}
                        direction="row"
                        gap={0.2}
                        flexWrap={"wrap"}
                      >
                        {state?.reqCreatedByDocIdList?.map((docId, index) => {
                          const item = state.createdByList.find(
                            (item) => item?.staffDocId === docId
                          );
                          return (
                            <Box
                              key={index}
                              sx={{
                                my: 1,
                                p: 0.2,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                border: "1px solid #3D398933",
                              }}
                            >
                              <StaffCardDropDown
                                item={item}
                                showCross={true}
                                handleClose={() => {
                                  const newCreatedByList =
                                    state.reqCreatedByDocIdList.filter(
                                      (item) => item !== docId
                                    );
                                  setState((prev) => ({
                                    ...prev,
                                    reqCreatedByDocIdList: newCreatedByList,
                                  }));
                                }}
                              />
                            </Box>
                          );
                        })}
                      </Stack>
                    )}
                  </Grid>
                </Grid>
              )}
            </Box>
          )}
          {/*assigned to  */}
          {!forVisualsPage && (
            <Box>
              <CheckBoxComponent
                checked={checkboxes.assignedTo}
                onChange={(e) => {
                  setCheckboxes((prev) => ({
                    ...prev,
                    assignedTo: !prev.assignedTo,
                  }));
                }}
                label="Assigned To"
              />
              {checkboxes.assignedTo && (
                <Grid container spacing={1.5}>
                  <Grid xs={12} item>
                    <TextFieldLabel title="Assigned To" />
                    <CustomSelectBox
                      displayEmpty
                      size="small"
                      fullWidth
                      multiple
                      value={state.reqAssignedToDocIdList}
                      renderValue={() => {
                        if (state.reqAssignedToDocIdList.length === 0)
                          return "Select";
                        return state.assignedToList
                          ?.filter((item) =>
                            state.reqAssignedToDocIdList.includes(item?._id)
                          )
                          ?.map(
                            (item) =>
                              FormatFullName(
                                item?.firstName,
                                item?.middleName,
                                item?.lastName
                              ) ?? item?.name
                          )
                          ?.join(", ");
                      }}
                    >
                      <MenuItem disabled value="">
                        Select
                      </MenuItem>
                      {state?.assignedToList?.map((item) => (
                        <Box key={item?._id} sx={{ px: 1 }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="secondary"
                                checked={state.reqAssignedToDocIdList.includes(
                                  item?._id
                                )}
                                onChange={() => {
                                  const newAssignedToList =
                                    state.reqAssignedToDocIdList.includes(
                                      item?._id
                                    )
                                      ? state.reqAssignedToDocIdList.filter(
                                          (docId) => docId !== item?._id
                                        )
                                      : [
                                          ...state.reqAssignedToDocIdList,
                                          item?._id,
                                        ];
                                  setState((prev) => ({
                                    ...prev,
                                    reqAssignedToDocIdList: newAssignedToList,
                                  }));
                                }}
                              />
                            }
                            label={<StaffCardDropDown item={item} />}
                          />
                        </Box>
                      ))}
                    </CustomSelectBox>
                    {state?.reqAssignedToDocIdList?.length > 0 && (
                      <Stack
                        spacing={1}
                        direction="row"
                        mt={1}
                        gap={0.2}
                        flexWrap={"wrap"}
                      >
                        {state?.reqAssignedToDocIdList?.map((docId, index) => {
                          const item = state.assignedToList.find(
                            (item) => item?._id === docId
                          );
                          return (
                            <Box
                              key={index}
                              sx={{
                                display: "flex",
                                border: "1px solid #3D398933",
                              }}
                            >
                              <StaffCardDropDown
                                item={item}
                                showCross={true}
                                handleClose={() => {
                                  const newAssignedToList =
                                    state.reqAssignedToDocIdList.filter(
                                      (item) => item !== docId
                                    );
                                  setState((prev) => ({
                                    ...prev,
                                    reqAssignedToDocIdList: newAssignedToList,
                                  }));
                                }}
                              />
                            </Box>
                          );
                        })}
                      </Stack>
                    )}
                  </Grid>
                </Grid>
              )}
            </Box>
          )}
          {/* probality */}
          {!forVisualsPage && (
            <Box>
              <CheckBoxComponent
                checked={checkboxes.admissionProbability}
                onChange={(e) => {
                  setCheckboxes((prev) => ({
                    ...prev,
                    admissionProbability: !prev.admissionProbability,
                  }));
                }}
                label="Admission Probability"
              />
              {checkboxes.admissionProbability && (
                <Grid container spacing={1.5}>
                  <Grid xs={6} item>
                    <TextFieldLabel title="From" />
                    <CustomTextField
                      size="small"
                      type="number"
                      value={state.admissionProbabilityFrom}
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          admissionProbabilityFrom: e.target.value,
                        }))
                      }
                    />
                  </Grid>
                  <Grid xs={6} item>
                    <TextFieldLabel title="To" />
                    <CustomTextField
                      size="small"
                      type="number"
                      value={state.admissionProbabilityTo}
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          admissionProbabilityTo: e.target.value,
                        }))
                      }
                    />
                  </Grid>
                </Grid>
              )}
            </Box>
          )}
          {/* Created On  */}
          <Box>
            <CheckBoxComponent
              checked={checkboxes.createdOn}
              onChange={(e) => {
                setCheckboxes((prev) => ({
                  ...prev,
                  createdOn: !prev.createdOn,
                }));
              }}
              label="Created On"
            />
            {checkboxes.createdOn && (
              <Grid container spacing={1.5}>
                <Grid xs={6} item>
                  <TextFieldLabel title="From" />
                  <DatePicker
                    inputFormat="DD-MM-YYYY"
                    value={state.createdFrom}
                    onChange={(e) =>
                      setState((prev) => ({ ...prev, createdFrom: e }))
                    }
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        placeholder="Select"
                        size="small"
                      />
                    )}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextFieldLabel title="To" />
                  <DatePicker
                    inputFormat="DD-MM-YYYY"
                    value={state.createdTo}
                    onChange={(e) =>
                      setState((prev) => ({ ...prev, createdTo: e }))
                    }
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        placeholder="Select"
                        size="small"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
          {/* source */}
          <Box>
            <CheckBoxComponent
              checked={checkboxes.source}
              onChange={(e) => {
                setCheckboxes((prev) => ({
                  ...prev,
                  source: !prev.source,
                }));
              }}
              label="Source"
            />
            {checkboxes.source && (
              <Grid container spacing={1.5}>
                <Grid xs={6} item>
                  <TextFieldLabel title="Source" />
                  <CustomSelectBox
                    displayEmpty
                    size="small"
                    fullWidth
                    value={state.selectedSource}
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        selectedSource: e.target.value,
                      }))
                    }
                  >
                    <MenuItem disabled value="">
                      Select
                    </MenuItem>

                    {state.sourcesData?.map((item, i) => (
                      <MenuItem key={i} value={item?.source}>
                        {item?.source ? item?.source : "N/A"}
                      </MenuItem>
                    ))}
                  </CustomSelectBox>
                </Grid>
              </Grid>
            )}
          </Box>
          {/* Community */}
          {forVisualsPage && (
            <Box>
              <CheckBoxComponent
                checked={checkboxes.community}
                onChange={(e) => {
                  setCheckboxes((prev) => ({
                    ...prev,
                    community: !prev.community,
                  }));
                }}
                label="Community"
              />
              {checkboxes.community && (
                <Grid container spacing={1.5}>
                  <Grid xs={6} item>
                    <TextFieldLabel title="Community" />
                    <CustomSelectBox
                      displayEmpty
                      size="small"
                      fullWidth
                      value={state.selectedCommunity}
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          selectedCommunity: e.target.value,
                        }))
                      }
                    >
                      <MenuItem disabled value="">
                        Select
                      </MenuItem>

                      {state.communityData?.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item ? item : "N/A"}
                        </MenuItem>
                      ))}
                    </CustomSelectBox>
                  </Grid>
                </Grid>
              )}
            </Box>
          )}
          {/* living type */}
          {forVisualsPage && (
            <Box>
              <CheckBoxComponent
                checked={checkboxes.livingType}
                onChange={(e) => {
                  setCheckboxes((prev) => ({
                    ...prev,
                    livingType: !prev.livingType,
                  }));
                }}
                label="Living Type"
              />
              {checkboxes.livingType && (
                <Grid container spacing={1.5}>
                  <Grid xs={6} item>
                    <TextFieldLabel title="Living Type" />
                    <CustomSelectBox
                      displayEmpty
                      size="small"
                      fullWidth
                      value={state.selectedLivingType}
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          selectedLivingType: e.target.value,
                        }))
                      }
                    >
                      <MenuItem disabled value="">
                        Select
                      </MenuItem>

                      {state.livingTypeData?.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item ? item : "N/A"}
                        </MenuItem>
                      ))}
                    </CustomSelectBox>
                  </Grid>
                </Grid>
              )}
            </Box>
          )}
          {/* Next Follow Up  */}
          {!forVisualsPage && (
            <Box>
              <CheckBoxComponent
                checked={checkboxes.nextFollowUp}
                onChange={(e) => {
                  setCheckboxes((prev) => ({
                    ...prev,
                    nextFollowUp: !prev.nextFollowUp,
                  }));
                }}
                label="Next Follow Up"
              />
              {checkboxes.nextFollowUp && (
                <Grid container spacing={1.5}>
                  <Grid xs={6} item>
                    <TextFieldLabel title="From" />
                    <DatePicker
                      inputFormat="DD-MM-YYYY"
                      value={state.nextFollowUpFrom}
                      onChange={(e) =>
                        setState((prev) => ({ ...prev, nextFollowUpFrom: e }))
                      }
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          placeholder="Select"
                          size="small"
                        />
                      )}
                    />
                  </Grid>
                  <Grid xs={6} item>
                    <TextFieldLabel title="To" />
                    <DatePicker
                      inputFormat="DD-MM-YYYY"
                      value={state.nextFollowUpTo}
                      onChange={(e) =>
                        setState((prev) => ({ ...prev, nextFollowUpTo: e }))
                      }
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          placeholder="Select"
                          size="small"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              )}
            </Box>
          )}
          {/* Enquiry On  */}
          {!forVisualsPage && (
            <Box>
              <CheckBoxComponent
                checked={checkboxes.enquiryOn}
                onChange={(e) => {
                  setCheckboxes((prev) => ({
                    ...prev,
                    enquiryOn: !prev.enquiryOn,
                  }));
                }}
                label="Enquiry On"
              />
              {checkboxes.enquiryOn && (
                <Grid container spacing={1.5}>
                  <Grid xs={6} item>
                    <TextFieldLabel title="From" />
                    <DatePicker
                      inputFormat="DD-MM-YYYY"
                      value={state.enquiryFrom}
                      onChange={(e) =>
                        setState((prev) => ({ ...prev, enquiryFrom: e }))
                      }
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          placeholder="Select"
                          size="small"
                        />
                      )}
                    />
                  </Grid>
                  <Grid xs={6} item>
                    <TextFieldLabel title="To" />
                    <DatePicker
                      inputFormat="DD-MM-YYYY"
                      value={state.enquiryTo}
                      onChange={(e) =>
                        setState((prev) => ({ ...prev, enquiryTo: e }))
                      }
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          placeholder="Select"
                          size="small"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              )}
            </Box>
          )}
          {/* Payment Status  */}
          {!forVisualsPage && (
            <Box>
              <CheckBoxComponent
                checked={checkboxes.paymentStatus}
                onChange={(e) => {
                  setCheckboxes((prev) => ({
                    ...prev,
                    paymentStatus: !prev.paymentStatus,
                  }));
                }}
                label="Payment Status"
              />
              {checkboxes.paymentStatus && (
                <Grid container spacing={1.5}>
                  <Grid xs={6} item>
                    <TextFieldLabel title="Payment Status" />
                    <CustomSelectBox
                      displayEmpty
                      size="small"
                      fullWidth
                      value={state.paymentStatus}
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          paymentStatus: e.target.value,
                        }))
                      }
                    >
                      <MenuItem disabled value="">
                        Select
                      </MenuItem>
                      <MenuItem value="Paid">Paid</MenuItem>
                      <MenuItem value="Not Paid">Not Paid</MenuItem>
                    </CustomSelectBox>
                  </Grid>
                </Grid>
              )}
            </Box>
          )}
          {/* Enquiry Status  */}
          <Box>
            <CheckBoxComponent
              checked={checkboxes.enquiryStatus}
              onChange={(e) => {
                setCheckboxes((prev) => ({
                  ...prev,
                  enquiryStatus: !prev.enquiryStatus,
                }));
              }}
              label="Enquiry Status"
            />
            {checkboxes.enquiryStatus && (
              <Grid container spacing={1.5}>
                <Grid xs={12} item>
                  <TextFieldLabel title="Enquiry Status" />
                  <CustomSelectBox
                    displayEmpty
                    size="small"
                    multiple
                    fullWidth
                    value={state.reqEnquiryStatusList}
                    renderValue={() => {
                      if (state.reqEnquiryStatusList.length === 0)
                        return "Select";
                      return state.reqEnquiryStatusList.join(", ");
                    }}
                  >
                    {state?.statusList?.map((item, i) => (
                      <Box key={i} sx={{ px: 1 }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="secondary"
                              checked={state.reqEnquiryStatusList.includes(
                                item?.status
                              )}
                              onChange={() => {
                                const newStatusList =
                                  state.reqEnquiryStatusList.includes(
                                    item?.status
                                  )
                                    ? state.reqEnquiryStatusList.filter(
                                        (status) => status !== item?.status
                                      )
                                    : [
                                        ...state.reqEnquiryStatusList,
                                        item?.status,
                                      ];

                                setState((prev) => ({
                                  ...prev,
                                  reqEnquiryStatusList: newStatusList,
                                }));
                              }}
                            />
                          }
                          label={item?.status}
                          componentsProps={{
                            typography: {
                              fontSize: "14px",
                              fontWeight: 600,
                            },
                          }}
                        />
                      </Box>
                    ))}
                  </CustomSelectBox>

                  {state?.reqEnquiryStatusList?.length > 0 && (
                    <Stack spacing={1} direction="row" mt={1} flexWrap={"wrap"}>
                      {state?.reqEnquiryStatusList?.map((status, index) => {
                        return (
                          <Box key={index} sx={{ p: 0.2 }}>
                            <Chip
                              title={status}
                              handleClose={() => {
                                const newStatusList =
                                  state.reqEnquiryStatusList.filter(
                                    (item) => item !== status
                                  );
                                setState((prev) => ({
                                  ...prev,
                                  reqEnquiryStatusList: newStatusList,
                                }));
                              }}
                            />
                          </Box>
                        );
                      })}
                    </Stack>
                  )}
                </Grid>
              </Grid>
            )}
          </Box>
        </DialogContent>
      </CommonFilterDialog>
    </>
  );
};

export default AdmissionEnquiryFilterDialog;
