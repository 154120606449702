import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import DialogHeader from "app/components/common/DialogHeader";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import { updateEmployeeId } from "app/services/schoolService/hr.service";
import { CustomTextField } from "assets/styles/globalStyledComponent";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import * as yup from "yup";

const EditEmployeeId = ({ prevState, handldCloseEditDialog, refetch }) => {
  const { mutate, isLoading: updateEmployeeInfoLoading } = useMutation(
    updateEmployeeId,
    {
      onSuccess: (res) => {
        toast.success(res?.data?.message);
        refetch();
        formik.resetForm();
        handldCloseEditDialog();
      },
      onError: (err) => {
        toast.error(err?.response?.data?.message);
      },
      enabled: false,
    }
  );

  const validationSchema = yup.object({
    employeeId: yup.string().required("Device NickName  Required"),
  });
  const formik = useFormik({
    initialValues: {
      employeeId: "",
    },
    validationSchema,
    onSubmit: (newEntityValues) => {
      let query = {
        staffDocId: prevState?.staffDocId,
        employeeId: newEntityValues?.employeeId,
      };
      mutate(query);
    },
  });
  useEffect(() => {
    formik.setValues((prev) => ({
      ...prev,
      employeeId: prevState?.employeeId,
    }));
  }, [prevState]);

  return (
    <>
      <Dialog
        open={prevState.editEmployeeDialog}
        onClose={handldCloseEditDialog}
        PaperProps={{
          sx: { minWidth: "25vw" },
        }}
      >
        <DialogHeader
          title="Edit Employee Code Dialog"
          handleClose={handldCloseEditDialog}
        />
        <DialogContent>
          <form onSubmit={formik.handleSubmit} id="add_form">
            <Grid
              container
              display={"flex"}
              alignItems={"center"}
              sx={{ p: 1, mr: 1 }}
              justifyContent={"space-between"}
              spacing={2}
            >
              <Grid xs={12} item>
                <TextFieldLabel title="Current Employee Code:" />
                <Typography sx={{ fontWeight: 600 }}>
                  {prevState?.employeeId ?? "--"}
                </Typography>
              </Grid>
              <Grid xs={12} item>
                <TextFieldLabel title="New Employee Code" />
                <CustomTextField
                  size="small"
                  fullWidth
                  name="employeeId"
                  value={formik?.values?.employeeId}
                  onChange={formik.handleChange}
                  placeholder="Type employeeId"
                  error={
                    Boolean(formik.errors.employeeId) &&
                    formik.touched.employeeId
                  }
                  helperText={
                    formik.touched.employeeId && formik.errors.employeeId
                  }
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            color="secondary"
            loading={updateEmployeeInfoLoading}
            variant="contained"
            size="large"
            Type="submit"
            form="add_form"
          >
            UPDATE
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditEmployeeId;
