import { LoadingButton } from "@mui/lab";
import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import { selectActiveSession } from "app/modules/schools/selectors";
import { MenuProps } from "app/utils/helper";
import profilePlaceHolder from "assets/media/images/idCard-Images/userImage.png";
import {
  CustomSelectBox,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import TextFieldLabel from "./TextFieldLabel";
import CustomChip from "./CustomChip";
import { setSelectedSession } from "app/modules/schools/operations";
import { getStudentByName } from "app/services/schoolService/studentReport.service";

const StudentSearchInput = ({
  placeholder = true,
  handleSearch = () => {},
  loading,
  button = true,
  chip = true,
  setSelectedStudent = () => {},
  setSelectedStudentData = () => {}, //For setting student data
  selectedStudentData = [],
}) => {
  const [state, setState] = useState({
    name: "",
    page: 1,
    studentData: {},
    menuOpen: false,
    selectedStudent: [],
  });

  useEffect(() => {
    if (selectedStudentData.length > 0) {
      setState((prev) => ({
        ...prev,
        selectedStudent: selectedStudentData,
      }));
    }
  }, [selectedStudentData]);

  const selectedSession = useSelector(selectActiveSession);
  const {
    isFetching: getStudentByNameFetching,
    isLoading: getStudentByNameLoading,
  } = useQuery({
    queryKey: ["getStudentByName", state.name, state.page],
    queryFn: () =>
      getStudentByName({
        session: selectedSession,
        studentName: state.name,
        page: state.page,
        active: true,
      }),
    onSuccess: (data) => {
      if (state.page !== 1) {
        setState((prev) => ({
          ...prev,
          studentData: { ...prev.studentData, ...data?.data },
        }));
      } else {
        setState((prev) => ({ ...prev, studentData: data?.data }));
      }
    },
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
    enabled: state.name ? true : false,
  });
  useEffect(() => {
    if (!button) {
      setSelectedSession();
    }
  }, [button, state.selectedStudent]);

  return (
    <Box>
      <Stack direction={"row"} gap={1} alignItems={"center"}>
        <Box>
          {placeholder && <TextFieldLabel title="Search Name" />}
          <CustomSelectBox
            size="small"
            sx={{ width: "250px", backgroundColor: "#fff" }}
            MenuProps={MenuProps}
            multiple
            value={state.selectedStudent}
            fullWidth
            open={state.menuOpen}
            onOpen={() => setState((prev) => ({ ...prev, menuOpen: true }))}
            onClose={() => setState((prev) => ({ ...prev, menuOpen: false }))}
            placeholder="Select"
            onChange={(e) => e.stopPropagation()}
            renderValue={() => {
              return <Typography>Select</Typography>;
            }}
          >
            <Box sx={{ px: 1 }}>
              <CustomTextField
                autoFocus
                sx={{ mb: 0.5 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {(getStudentByNameFetching ||
                        getStudentByNameLoading) && (
                        <CircularProgress size={20} />
                      )}
                    </InputAdornment>
                  ),
                }}
                placeholder="Type Name"
                onChange={(e) => {
                  e.stopPropagation();
                  setState((prev) => ({
                    ...prev,
                    name: e.target.value,
                    ...(state.page !== 1 && { page: 1 }),
                    ...(!e.target.value && { studentData: {} }),
                  }));
                }}
                value={state.name}
                fullWidth
                size="small"
              />
              {state.studentData?.list?.map((item, ind) => (
                <Box key={ind}>
                  <Grid
                    container
                    spacing={1}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <Grid
                      item
                      xs={10}
                      alignItems={"center"}
                      gap={2}
                      display={"flex"}
                    >
                      <Grid item xs={2}>
                        <img
                          src={item?.profilePic ?? profilePlaceHolder}
                          alt="profile"
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                          }}
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                          {item?.name}
                        </Typography>
                        <Typography sx={{ fontWeight: 500, fontSize: "12px" }}>
                          S/O:- {item?.fatherName}
                        </Typography>
                        <Typography sx={{ fontWeight: 500, fontSize: "12px" }}>
                          Reg No:- {item?.registrationNumber}
                        </Typography>
                        <Typography sx={{ fontWeight: 500, fontSize: "12px" }}>
                          Adm No:- {item?.admissionNumber}
                        </Typography>
                        <Typography sx={{ fontWeight: 500, fontSize: "12px" }}>
                          Class Name:- {item?.classInfo}
                        </Typography>
                      </Grid>
                    </Grid>
                    {/* check box */}
                    <Grid item xs={2}>
                      <Checkbox
                        color="secondary"
                        checked={state.selectedStudent
                          .map((id) => id.studentDocId)
                          .includes(item?.studentDocId)}
                        onChange={() => {
                          if (
                            state.selectedStudent
                              .map((id) => id.studentDocId)
                              .includes(item?.studentDocId)
                          ) {
                            state.selectedStudent =
                              state.selectedStudent.filter(
                                (res) =>
                                  res?.studentDocId !== item?.studentDocId
                              );
                          } else {
                            state.selectedStudent.push(item);
                          }
                          if (!button) {
                            setSelectedStudent(
                              state.selectedStudent.map(
                                (res) => res.studentDocId
                              )
                            );
                            setSelectedStudentData(state.selectedStudent);
                          }
                          setState((prev) => ({ ...prev }));
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Divider
                    sx={{
                      my: 0.5,
                      backgroundColor: "#3b3939",
                    }}
                  />
                </Box>
              ))}
              {state.studentData?.pages !== state.studentData?.page && (
                <Stack direction={"row"} justifyContent={"center"}>
                  <Typography
                    sx={{
                      color: "blue",
                      textDecoration: "underline",
                      cursor: "pointer",
                      textAlign: "center",
                      display: "inline-block",
                      mb: 1,
                    }}
                    onClick={() =>
                      setState((prev) => ({ ...prev, page: prev.page + 1 }))
                    }
                  >
                    more
                  </Typography>
                </Stack>
              )}
            </Box>
          </CustomSelectBox>
        </Box>
        {button && (
          <LoadingButton
            loading={loading}
            disabled={state.selectedStudent.length === 0 ? true : false}
            color="secondary"
            variant="contained"
            size="large"
            sx={{ mt: 2.5 }}
            onClick={() =>
              handleSearch(state.selectedStudent.map((res) => res.studentDocId))
            }
          >
            Search
          </LoadingButton>
        )}
      </Stack>
      {chip && (
        <Stack direction="row" gap={1} flexWrap={"wrap"} pt={1}>
          {state.selectedStudent?.map((item, ind) => (
            <CustomChip
              key={ind}
              deleteIcon
              handleClose={() => {
                state.selectedStudent = state.selectedStudent.filter(
                  (res) => res?.studentDocId !== item?.studentDocId
                );
                setSelectedStudent(
                  state.selectedStudent.map((res) => res.studentDocId)
                );
                setSelectedStudentData(state.selectedStudent);
                setState((prev) => ({ ...prev }));
              }}
              title={item?.name}
            />
          ))}
        </Stack>
      )}
    </Box>
  );
};

export default StudentSearchInput;
