import React, { useEffect, useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import styled, { keyframes } from "styled-components";
import { screen } from "app/utils/mixins";
import { Button } from "react-bootstrap";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import {
  CaretRightFill,
  CaretLeftFill,
  BuildingGear,
  Building,
} from "react-bootstrap-icons";
import { selectIsAuthorised } from "app/modules/auth/selectors";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Stack,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MobileHeader from "./MobileHeader";
import MobileSidebar from "./MobileSidebar";
import { Link, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import appRoutes from "app/config/routes";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import { getSchoolProfile } from "app/services/management";
import DeactivateContent from "./DeactivateContent";
import Policies from "./Policies";
import { setPolicyTimeout } from "app/services/commonSlice";
import { BlinkingNumber, BlinkingNumberText } from "app/utils/helper";
const bottomStripHeight = "25px";

const Layout = ({ children }) => {
  const { policyExpireTime } = useSelector((store) => store.commonStore);
  const [isSidebarToggled, setIsSidebarToggled] = useState(true);
  const [show, setShow] = useState(false);
  const [isPolicyAccepted, setisPolicyAccepted] = useState(true);
  const isAuthenticated = useSelector(selectIsAuthorised);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const location = useLocation();
  const [visible, setVisible] = useState(true);
  const dispatch = useDispatch();
  const handleSidebarToggled = () => {
    setIsSidebarToggled(!isSidebarToggled);
  };

  const handleOnClickHamburgeMenu = () => {
    setIsSidebarToggled(!isSidebarToggled);
  };
  const handleClosePolicyDialog = () => {
    dispatch(setPolicyTimeout(new Date()));
    setisPolicyAccepted(true);
  };
  const {
    data: getSchoolProfileData,
    isSuccess: getSchoolProfileDataIsSuccess,
    refetch: getSchoolProfileRefetch,
  } = useQuery({
    queryKey: ["getSchoolProfile"],
    queryFn: () => getSchoolProfile(),
    onSuccess: (success) => {
      let isAccepted = true;
      success?.data?.obj?.legals?.forEach((item) => {
        if (!item?.accepted) {
          isAccepted = false;
          return;
        }
      });
      if (isAccepted) {
        dispatch(setPolicyTimeout(""));
        handleClosePolicyDialog();
      }
    },
    onError: (data) => {
      toast.error(data?.response?.data?.message);
    },
  });

  const dueDate = dayjs(getSchoolProfileData?.data?.obj?.upcomingDueDate);
  const currentDate = dayjs(new Date());
  useEffect(() => {
    if (getSchoolProfileDataIsSuccess) {
      let isAccepted = true;
      getSchoolProfileData?.data?.obj?.legals?.forEach((item) => {
        if (!item?.accepted) {
          isAccepted = false;
          return;
        }
      });
      if (!isAccepted) {
        if (!policyExpireTime) {
          setisPolicyAccepted(isAccepted);
        } else {
          const prevTime = dayjs(policyExpireTime);
          const diff = currentDate.diff(prevTime, "hours");

          if (diff >= 1) {
            setisPolicyAccepted(isAccepted);
          }
        }
      }
    }
  }, [location, getSchoolProfileDataIsSuccess]);

  return (
    <>
      <Policies
        legals={getSchoolProfileData?.data?.obj?.legals}
        refetch={getSchoolProfileRefetch}
        open={!isPolicyAccepted}
        onClose={handleClosePolicyDialog}
      />
      <StyledWrapper id="layout">
        {isAuthenticated ? (
          isMobile ? (
            <>
              <MobileHeader setShow={setShow} />
            </>
          ) : (
            <div>
              <Header handleOnClickHamburgeMenu={handleOnClickHamburgeMenu} />
            </div>
          )
        ) : null}
        {isMobile ? (
          <>
            <SwipeableDrawer
              open={show}
              onClose={() => {
                setShow(false);
              }}
              onOpen={() => {
                setShow(true);
              }}
              anchor="left"
              PaperProps={{
                sx: {},
              }}
            >
              <MobileSidebar setShow={setShow} />
            </SwipeableDrawer>
            <div className="w-100 p-3">
              <Box
                className="main-content"
                sx={{
                  pt: "70px",
                  overflowY: "auto",
                  height: "calc(100vh - 20px)",
                }}
              >
                {children}
              </Box>
            </div>
          </>
        ) : (
          <>
            <Stack direction="row">
              <Box
                className={`sidebar-wrapper shadow d-sm-block ${
                  !isSidebarToggled ? "d-none" : ""
                } `}
              >
                {isAuthenticated ? (
                  <Sidebar isSidebarToggled={isSidebarToggled} />
                ) : null}
              </Box>
              <Button
                variant="link"
                className="align-self-start d-sm-none mt-2"
                onClick={handleSidebarToggled}
              >
                {isSidebarToggled ? <CaretRightFill /> : <CaretLeftFill />}
              </Button>
              <Box sx={{ width: "100%", position: "relative" }}>
                {(dueDate.diff(currentDate, "day") <= 15 ||
                  currentDate.isSame(dueDate)) && (
                  <MarqueText>
                    <Typography className="marque_text">
                      Kindly settle your service charge prior to the date{" "}
                      {dueDate.format("DD MMM, YYYY")} to ensure uninterrupted
                      service operation.-{" "}
                      <Link to={`/${appRoutes.schoolProfile}?tab=transaction`}>
                        See More
                      </Link>
                    </Typography>
                  </MarqueText>
                )}
                {/* <Box
                  className="main-content px-3 for-scroll"
                  sx={{
                    paddingTop: "20px",
                    height: "calc(100vh - 95px)",
                    overflowY: "auto",
                    // pb: bottomStripHeight,
                  }}
                >
                  {(getSchoolProfileData?.data?.obj?.accountStatus !==
                    "ACTIVE" ||
                    !isPolicyAccepted) &&
                  location.pathname !== `/${appRoutes.schoolProfile}` &&
                  getSchoolProfileDataIsSuccess ? (
                    <>
                      <Stack
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        height={"100%"}
                      >
                        {isPolicyAccepted ? (
                          <DeactivateContent />
                        ) : (
                          <Policies
                            legals={getSchoolProfileData?.data?.obj?.legals}
                            refetch={getSchoolProfileRefetch}
                          />
                        )}
                      </Stack>
                    </>
                  ) : (
                    children
                  )}
                </Box> */}
                <Box
                  className="main-content px-3 for-scroll"
                  sx={{
                    paddingTop: "20px",
                    height: "calc(100vh - 95px)",
                    overflowY: "auto",
                    // pb: bottomStripHeight,
                  }}
                >
                  {getSchoolProfileData?.data?.obj?.accountStatus !==
                    "ACTIVE" &&
                  location.pathname !== `/${appRoutes.schoolProfile}` &&
                  location.pathname !== `/${appRoutes.billsUsage}` &&
                  getSchoolProfileDataIsSuccess ? (
                    <>
                      <Stack
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        height={"100%"}
                      >
                        <DeactivateContent />
                      </Stack>
                    </>
                  ) : (
                    children
                  )}
                </Box>
                {/* manage height if bottom strip */}
                <Box sx={{ height: bottomStripHeight }} />
              </Box>
            </Stack>
            {/* bottom Strip */}
            <Box
              sx={{
                backgroundColor: "#f9f9f9",
                width: "100%",
                height: bottomStripHeight,
                position: "fixed",
                bottom: 0,
                borderTop: "1px solid rgba(0, 0, 0,0.3)",
                px: 4,
                zIndex: 1000,
              }}
            >
              <Stack
                direction="row"
                justifyContent={"space-between"}
                alignItems={"center"}
                height="100%"
              >
                <Stack direction={"row"} gap={0.5}>
                  <Typography>Support :-</Typography>
                  <BlinkingNumber />
                </Stack>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  gap={2}
                >
                  <a
                    href="https://www.linkedin.com/company/ekalsutraofficial/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <LinkedInIcon style={{ color: "#0077b5" }} /> LinkedIn
                  </a>
                  <a
                    href="https://www.facebook.com/ekalsutrahelp"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <FacebookIcon style={{ color: "#0077b5" }} />
                    Facebook
                  </a>
                  <a
                    href="https://www.instagram.com/ekalsutra/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <InstagramIcon style={{ color: "#E1306C" }} />
                    Instagram
                  </a>
                  <a
                    href="https://www.youtube.com/@ekalsutra"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <YouTubeIcon style={{ color: "#FF0000" }} />
                    YouTube
                  </a>
                  <a
                    href="https://twitter.com/EkalSutra"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <TwitterIcon style={{ color: "#1DA1F2" }} />
                    X/Twitter
                  </a>
                </Stack>
                <Stack direction="row" gap={2} alignItems="center">
                  <Stack
                    direction="row"
                    gap={0.5}
                    component={Link}
                    to="/schools/about-school"
                    sx={{ textDecoration: "none" }}
                    alignItems="center"
                  >
                    <BuildingGear style={{ color: "#000", opacity: 0.8 }} />
                    <Typography sx={{ color: "primary.main", fontWeight: 400 }}>
                      About School
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    gap={0.5}
                    component={Link}
                    to="/schools/school-profile"
                    sx={{ textDecoration: "none" }}
                    alignItems="center"
                  >
                    <Building style={{ color: "#000", opacity: 0.8 }} />
                    <Typography sx={{ color: "primary.main", fontWeight: 400 }}>
                      School Profile
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Box>
          </>
        )}
      </StyledWrapper>
    </>
  );
};

export default Layout;

const StyledWrapper = styled.div`
  .for-scroll {
    ::-webkit-scrollbar {
      width: 10px;
    }

    ::-webkit-scrollbar-track {
      // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    ::-webkit-scrollbar-thumb {
      background-color: rgba(8, 76, 132, 1);
      /* outline: 1px solid rgba(8, 76, 132, 1); */
      border-radius: 10px;
    }
  }
  /* background-color: #fbfbff; */
  background-color: #f0f2f5;
  .bg-blue {
    background: blue;
  }
  ul {
    padding: 0px;
  }
  .sidebar-wrapper {
    background: #084c84;
    margin: 0;
    height: "100%";
    ${screen.medium.standard`
      width: 100px
    `};
    transition: 0.4s;
  }
`;
const marqueKeyFrams = keyframes`
 0% { transform: translate(0, 0); }
    100% { transform: translate(-100%, 0); }
`;
const MarqueText = styled.div`
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 2000;

  .marque_text {
    display: inline-block;
    padding-left: 100%;
    color: red;
    font-weight: 600;
    font-size: 16px;
    animation: ${marqueKeyFrams} 20s linear infinite;
    cursor: pointer;
    :hover {
      animation-play-state: paused;
    }
    span {
      text-decoration: underline;
      color: blue;
    }
  }
`;
