import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { useMemo, useState } from "react";
import { Eye } from "react-bootstrap-icons";
import "react-notifications/lib/notifications.css";
import { LoadingButton } from "@mui/lab";
import { format } from "date-fns";
import { getStudentProfileByRequestDocId } from "../../../../services/student-management";
import AdmissionApprovalModal from "./AdmissionApprovalModal";
import { CustomTextField } from "assets/styles/globalStyledComponent";
import CommonTable1 from "app/components/Tables/CommonTable1";
import dayjs from "dayjs";
import { DATE_FORMAT } from "app/config/constants";
import { useQuery } from "react-query";
import { getStudentAdmissionRequests } from "app/services/schoolService/studentProfile";
import { toast } from "react-toastify";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import ExportButtonGroup from "app/components/common/ExportButtonGroup";
import ExportDialog from "app/components/common/exportDialog";
import SummaryCommonComponents from "app/components/common/SummaryCommonComponents";
import {
  calculateTotalsAndAppendSummaryRowPdf,
  FormatFullName,
} from "app/utils/helper";
import * as XLSX from "xlsx";
import PdfSummaryPreviewDialog from "app/components/common/Dialogs/PdfSummaryPreviewDialog";
import { useSelector } from "react-redux";
import jsPDF from "jspdf";
import {
  selectActiveSession,
  selectSelectedSchool,
  userNameSelector,
} from "app/modules/schools/selectors";
import DialogHeader from "app/components/common/DialogHeader";
import reportHeader from "app/components/common/pdfReport/reportHeader";
import moment from "moment";
import autoTable from "jspdf-autotable";
import Footer from "app/components/common/pdfReport/ReportFooter";
import YouTubeFloating from "app/components/common/floatingButton/YouTubeFloating";
import { StudentManagementAdmissionApprovalinks } from "app/utils/youTubeLink";
export default function AdmissionRequest() {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [fromDate, setFromDate] = useState(dayjs().subtract(1, "day"));
  const [toDate, setToDate] = useState(new Date());
  const [isViewModalVisible, setIsViewModalVisible] = useState(false);
  const [details, setDetails] = useState({});
  const [requestedOn, setrequestedOn] = useState("");
  const [requestedFor, setrequestedFor] = useState("");
  const [requestDocId, setRequestDocId] = useState("");
  const [status, setStatus] = useState("");
  const selectedSchool = useSelector(selectSelectedSchool);
  const session = useSelector(selectActiveSession);
  const [state, setState] = useState({
    t_requested: 0,
    t_approved: 0,
    t_rejected: 0,
    t_withDrawn: 0,
    checkExportOptionsExcel: false,
    checkExportOptionsPdf: false,
  });
  const {
    isLoading: getStudentAdmissionRequestsLoading,
    isFetching: getStudentAdmissionRequestsFetching,
    data: getStudentAdmissionRequestsData,
    refetch: getStudentAdmissionRequestsRefetch,
  } = useQuery({
    queryFn: () => getStudentAdmissionRequests({ from: fromDate, to: toDate }),
    queryKey: ["getStudentAdmissionRequests"],
    onSuccess: (success) => {
      setState((prev) => ({
        ...prev,
        t_requested: success?.data?.t_requested,
        t_approved: success?.data?.t_approved,
        t_rejected: success?.data?.t_rejected,
        t_withDrawn: success?.data?.t_withDrawn,
      }));
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
    enabled: false,
  });

  const {
    isLoading: getStudentProfileByRequestDocIdLoading,
    isFetching: getStudentProfileByRequestDocIdFetching,
  } = useQuery({
    queryFn: () => getStudentProfileByRequestDocId(requestDocId),
    queryKey: ["getStudentProfileByRequestDocId", requestDocId],
    onSuccess: (success) => {
      setDetails(success?.data?.obj);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
    enabled: requestDocId ? true : false,
  });

  const handleViewAction = (cell) => {
    setRequestDocId(cell?.row?.original?._id);
    setrequestedFor(cell?.row?.original?.className);
    setrequestedOn(cell?.row?.original?.requestedOn);
    setIsViewModalVisible(true);
    setStatus(cell?.row?.original?.status);
  };
  const [openCheckBox, setOpenCheckBox] = useState(false);
  let header = [
    "#",
    "Student Name",
    "Class-Stream",
    "Requested On",
    "Status",
    "address",
    "fatherName",
    "mobileNumber",
    "gender",
    "email",
  ];
  let headerPdf = [
    "#",
    "Student Name",
    "Class-Stream",
    "Requested On",
    "Status",
    "address",
    "fatherName",
    "mobileNumber",
    "gender",
    "email",
  ];
  let exportArray = getStudentAdmissionRequestsData?.data?.list?.map(
    (item, index) => {
      return {
        "#": index < 9 ? `0${index + 1}` : index + 1,
        "Student Name": item?.fullName ?? "--",
        "Class-Stream": `${item?.className}-${item?.stream}` ?? "--",
        "Requested On":
          dayjs(new Date(item?.requestedOn)).format("DD-MM-YYYY") ?? "--",
        Status: item?.status ?? "--",
        address:
          `${item?.studentDocId?.currentAddress?.address}, ${item?.studentDocId?.currentAddress?.dist}, ${item?.studentDocId?.currentAddress?.state}, ${item?.studentDocId?.currentAddress?.pinCode}` ??
          "--",
        fatherName: item?.studentDocId?.fatherInfo?.firstName
          ? FormatFullName(
              item?.studentDocId?.fatherInfo?.firstName,
              item?.studentDocId?.fatherInfo?.firstName,
              item?.studentDocId?.fatherInfo?.firstName
            )
          : "--",
        mobileNumber: item?.studentDocId?.mobileNumber ?? "--",
        gender: item?.studentDocId?.gender ?? "--",
        email: item?.studentDocId?.email ?? "--",
      };
    }
  );

  let exportArrayForPdf = getStudentAdmissionRequestsData?.data?.list?.map(
    (item, index) => {
      return {
        "#": index < 9 ? `0${index + 1}` : index + 1,
        "Student Name": item?.fullName ?? "--",
        "Class-Stream": `${item?.className}-${item?.stream}` ?? "--",
        "Requested On":
          dayjs(new Date(item?.requestedOn)).format("DD-MM-YYYY") ?? "--",
        Status: item?.status ?? "--",
        address:
          `${item?.studentDocId?.currentAddress?.address}, ${item?.studentDocId?.currentAddress?.dist}, ${item?.studentDocId?.currentAddress?.state}, ${item?.studentDocId?.currentAddress?.pinCode}` ??
          "--",
        fatherName: item?.studentDocId?.fatherInfo?.firstName
          ? FormatFullName(
              item?.studentDocId?.fatherInfo?.firstName,
              item?.studentDocId?.fatherInfo?.firstName,
              item?.studentDocId?.fatherInfo?.firstName
            )
          : "--",
        mobileNumber: item?.studentDocId?.mobileNumber ?? "--",
        gender: item?.studentDocId?.gender ?? "--",
        email: item?.studentDocId?.email ?? "--",
      };
    }
  );
  const { data: getStudentAdmissionRequestsData2 } = useQuery({
    queryFn: () => getStudentAdmissionRequests(),
    queryKey: ["getStudentAdmissionRequests"],
    onSuccess: (success) => {
      setState((prev) => ({
        ...prev,
        t_requested: success?.data?.t_requested,
        t_approved: success?.data?.t_approved,
        t_rejected: success?.data?.t_rejected,
        t_withDrawn: success?.data?.t_withDrawn,
      }));
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });
  const [selectedOptions, setSelectedOptions] = useState(header);
  const [selectedOptionsForPdf, setSelectedOptionsForPdf] = useState(headerPdf);
  const userName = useSelector(userNameSelector);
  const columns = useMemo(() => {
    return [
      {
        header: "Sr.No",
        Cell: ({ cell }) => (
          <p
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {parseInt(cell.row.id) + 1}
          </p>
        ),

        size: isMobile ? 40 : 20,
      },
      {
        header: "Enquiry Person",
        accessorKey: "fullName",
        size: isMobile ? 60 : 80,
      },
      {
        header: "Requested For",
        accessorKey: "className",
        size: isMobile ? 60 : 60,
      },
      {
        header: "Requested On",

        accessorKey: "requestedOn",
        id: "requestedOn",
        accessorfn: (row) => row?.requestedOn,
        Cell: ({ cell }) => {
          return format(new Date(cell.getValue()), "dd MMM, yyyy");
        },
        size: isMobile ? 60 : 60,
      },
      {
        header: "Status",
        accessorKey: "status",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <Box
              sx={{
                px: isMobile ? 0.3 : 1,
                py: 0.5,
                borderRadius: "20px",
                borderWidth: "1px",
                borderStyle: "solid",
                display: "inline-block",
                backgroundColor:
                  original?.status === "REQUESTED"
                    ? "rgba(241, 151, 15, 0.05)"
                    : original?.status === "APPROVED"
                    ? "rgba(20, 241, 15, 0.03)"
                    : original?.status === "IN_PROGRESS"
                    ? "rgba(241, 151, 15, 0.05)"
                    : "rgba(241, 15, 15, 0.05)",
                borderColor:
                  original?.status === "REQUESTED"
                    ? "rgba(241, 151, 15, 0.3)"
                    : original?.status === "APPROVED"
                    ? "rgba(7, 177, 3, 0.25)"
                    : original?.status === "IN_PROGRESS"
                    ? "rgba(241, 151, 15, 0.3)"
                    : "rgba(241, 15, 15, 0.3)",
              }}
            >
              <Typography
                sx={{
                  color:
                    original?.status === "REQUESTED"
                      ? "rgba(211, 132, 12, 1)"
                      : original?.status === "APPROVED"
                      ? "rgba(7, 177, 3, 1)"
                      : original?.status === "IN_PROGRESS"
                      ? "rgba(211, 132, 12, 1)"
                      : "rgba(222, 15, 15, 1)",
                  fontWeight: 500,
                }}
              >
                {original?.status}
              </Typography>
            </Box>
          );
        },
        size: isMobile ? 80 : 60,
      },
      {
        header: "Actions",
        Cell: ({ cell }) => {
          return (
            <Button
              onClick={() => {
                handleViewAction(cell);
              }}
              style={{
                backgroundColor: "#EBF0F4",
                borderRadius: "8px",
                border: "none",
                outline: "none",
                width: isMobile ? "10px" : "2.5rem",
                height: "2.5rem",
              }}
            >
              <Eye />
            </Button>
          );
        },
        size: isMobile ? 100 : 30,
      },
    ];
  }, []);

  let summaryArray = [
    {
      key: "Total Requested",
      value: state.t_requested,
    },
    {
      key: "Total Approved",
      value: state.t_approved,
    },
    {
      key: "Total Rejected",
      value: state.t_rejected,
    },
    {
      key: "Total Withdrawn",
      value: state.t_withDrawn,
    },
  ];

  const handleExportExcel = () => {
    // Get all unique keys from exportArray items
    // const allOptions = exportArray
    //   ? [...new Set(exportArray.flatMap(Object.keys))]
    //   : [];

    const filteredExportArray = exportArray?.map((item) => {
      const filteredItem = {};
      // Check if allOptions is defined before calling forEach
      selectedOptions?.forEach((option) => {
        //   allOptions?.forEach((option) => {
        filteredItem[option] = item[option];
      });
      return filteredItem;
    });
    let columnTotals = {};
    filteredExportArray.forEach((item) => {
      const keys = ["Balance", "Dues"];

      Object.keys(item).forEach((key) => {
        if (!columnTotals[key]) {
          columnTotals[key] = 0;
        }
        if (keys.includes(key) && !isNaN(item[key])) {
          columnTotals[key] += Number(item[key]);
        } else {
          columnTotals[key] = "";
        }
      });
    });

    const worksheetData = [
      [],
      [],
      [],
      Object.keys(filteredExportArray[0] || {}).map((key) => key),
      ...filteredExportArray.map((item) => Object.values(item)),
      Object.values(columnTotals),
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Bio Attendance");

    const addDataToSheet = (worksheet, data, origin) => {
      XLSX.utils.sheet_add_aoa(worksheet, [[data]], { origin });
    };

    const dataPoints = [
      { data: `School Name: ${selectedSchool?.schoolName}`, origin: "E1" },
      { data: `Session: ${session}`, origin: "D2" },
      {
        data: `Downloaded On: ${dayjs(new Date()).format("DD-MM-YYYY")}`,
        origin: "H2",
      },
    ];
    dataPoints.forEach(({ data, origin }) =>
      addDataToSheet(worksheet, data, origin)
    );

    XLSX.writeFile(workbook, "Admission Request.xlsx");
  };

  const handleCheckboxChangeForPdf = (option, isChecked) => {
    if (isChecked) {
      setSelectedOptionsForPdf((prevSelectedOptions) => [
        ...prevSelectedOptions,
        option,
      ]);
    } else {
      setSelectedOptionsForPdf((prevSelectedOptions) =>
        prevSelectedOptions.filter(
          (selectedOption) => selectedOption !== option
        )
      );
    }
  };

  const handleExportPdf = async () => {
    const doc = new jsPDF({
      orientation: "landscape",
    });

    const rows = [];
    rows.push(selectedOptionsForPdf);
    exportArrayForPdf.forEach((item) => {
      const row = selectedOptionsForPdf.map((option) => item[option]);
      rows.push(row);
    });
    const tableColumn = selectedOptionsForPdf.map((col) => ({
      title: col,
      dataKey: col,
    }));
    const tableStyles = {
      headStyles: {
        fillColor: [182, 200, 214],
        textColor: 0,
        fontSize: 8,
        cellPadding: { top: 2, right: 2, bottom: 2, left: 2 },
        cellWidth: "auto", // Enable text wrapping
      },
      bodyStyles: {
        fontSize: 6,
        cellPadding: { top: 2, right: 2, bottom: 2, left: 2 }, // Adjust padding as needed
        cellWidth: "auto", // Enable text wrapping
        textColor: [0, 0, 0],
      },
      columnStyles: {
        4: { cellWidth: 35, overflow: "linebreak" },
      },
      theme: "grid",
    };

    const totalPagesExp = "{total_pages_count_string}";

    // report header
    reportHeader({
      doc: doc,
      selectedSchool: selectedSchool,
      state: state,
      extraData: state?.filterExtraData,
      // extraData: state?.filterExtraData,
    });

    autoTable(doc, {
      head: [tableColumn],
      body: rows,
      startY: 40,
      didDrawPage: (data) => {
        Footer({
          doc: doc,
          data: data,
          userName: userName,
          totalPagesExp: totalPagesExp,
        });
      },
      ...tableStyles,
    });

    if (typeof doc.putTotalPages === "function") {
      doc.putTotalPages(totalPagesExp);
    }

    doc.save("Admission Request.pdf");
  };
  return (
    <>
      {/* <YoutubeVideoDialog
        open={openYoutubeDialog}
        handleCloseDialog={() => setOpenYoutubeDialog(false)}
        videoLink={videoLink}
      /> */}
      <ExportDialog
        open={openCheckBox}
        handleClose={() => setOpenCheckBox(false)}
        header={header}
        exportArray={exportArray}
        fileName="Admission_Request"
      />

      <YouTubeFloating videoLink={StudentManagementAdmissionApprovalinks} />

      <AdmissionApprovalModal
        refetch={() => getStudentAdmissionRequestsRefetch()}
        open={isViewModalVisible}
        setOpen={() => {
          setIsViewModalVisible(false);
          setRequestDocId("");
        }}
        data={details}
        requestedFor={requestedFor}
        requestedOn={requestedOn}
        requestDocId={requestDocId}
        status={status}
        loading={
          getStudentProfileByRequestDocIdLoading ||
          getStudentProfileByRequestDocIdFetching
            ? true
            : false
        }
      />
      <PdfSummaryPreviewDialog
        excel={true}
        allSelected={true}
        open={state?.checkExportOptionsExcel}
        handleClose={() =>
          setState((prev) => ({ ...prev, checkExportOptionsExcel: false }))
        }
        data={header}
        setSelectedOptionsForPdf={setSelectedOptions}
        selectedOptionsForPdf={selectedOptions}
        handleExportPdf={handleExportExcel}
      />

      <Dialog
        open={state.checkExportOptionsPdf}
        onClose={() =>
          setState((prev) => ({
            ...prev,
            checkExportOptionsPdf: false,
          }))
        }
        PaperProps={{
          sx: { minWidth: "400px" },
        }}
      >
        <DialogHeader
          title="Select Information to show in the pdf file"
          handleClose={() =>
            setState((prev) => ({
              ...prev,
              checkExportOptionsPdf: false,
            }))
          }
        />
        <DialogContent>
          <Box>
            {headerPdf.map((option) => (
              <Stack direction="row">
                <FormControlLabel
                  key={option}
                  control={
                    <Checkbox
                      checked={selectedOptionsForPdf.includes(option)}
                      onChange={(e) =>
                        handleCheckboxChangeForPdf(option, e.target.checked)
                      }
                    />
                  }
                  label={option}
                />
              </Stack>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ mb: 1 }}
            onClick={() => {
              setSelectedOptionsForPdf(headerPdf);
              handleExportPdf();
              setState((prev) => ({
                ...prev,
                checkExportOptionsPdf: false,
              }));
            }}
          >
            DownLoad
          </Button>
        </DialogActions>
      </Dialog>

      <Stack
        direction="row"
        alignItems="flex-end"
        gap={1.5}
        sx={{ pt: 1 }}
        justifyContent={"space-between"}
      >
        <Stack direction="row" gap={1}>
          <Box sx={{ width: "15vw", flex: isMobile && "1" }}>
            <TextFieldLabel title={"From"} required />
            <DesktopDatePicker
              inputFormat={DATE_FORMAT.indianDate}
              value={fromDate}
              fullWidth
              onChange={(newValue) => {
                setFromDate(newValue);
              }}
              renderInput={(params) => (
                <CustomTextField {...params} fullWidth />
              )}
            />
          </Box>

          <Box sx={{ width: "15vw", flex: isMobile && "1" }}>
            <TextFieldLabel title={"To"} required />
            <DesktopDatePicker
              inputFormat={DATE_FORMAT.indianDate}
              value={toDate}
              onChange={(newValue) => {
                setToDate(newValue);
              }}
              renderInput={(params) => (
                <CustomTextField {...params} fullWidth />
              )}
            />
          </Box>

          <LoadingButton
            variant="contained"
            loading={
              getStudentAdmissionRequestsLoading ||
              getStudentAdmissionRequestsFetching
            }
            color="secondary"
            sx={{
              width: "15%",
              py: 1.2,
              mb: 0.5,
              mt: 2,
            }}
            onClick={() => getStudentAdmissionRequestsRefetch()}
          >
            View
          </LoadingButton>
        </Stack>
        <Stack
          direction="row"
          alignItems="flex-end
    "
          gap={2}
          sx={{ pt: 1 }}
        >
          <Box>
            <ExportButtonGroup
              handleClickExcel={() => {
                setState((prev) => ({
                  ...prev,
                  checkExportOptionsExcel: true,
                }));
              }}
              showPdf={true}
              handleClickPdf={() => {
                setState((prev) => ({
                  ...prev,
                  checkExportOptionsPdf: true,
                }));
              }}
            />
          </Box>
          {/* <YouTube
            sx={{
              color: "#FF0000",
              fontSize: "2.5rem",
              cursor: "pointer",
            }}
            onClick={() => setOpenYoutubeDialog(true)}
          /> */}
        </Stack>
      </Stack>

      <SummaryCommonComponents summaryArray={summaryArray} />

      {/* table section  */}
      <Box sx={{ mt: 2 }}>
        <CommonTable1
          columns={columns}
          data={
            getStudentAdmissionRequestsData?.data?.list ||
            getStudentAdmissionRequestsData2?.data?.list
          }
        />
      </Box>
    </>
  );
}
