import React from "react";
import { Box, colors, IconButton, Stack, Typography } from "@mui/material";
import imagePlaceHolder from "assets/media/images/idCard-Images/userImage.png";
import { FormatFullName } from "app/utils/helper";
import { Close } from "@mui/icons-material";

export const StaffCardDropDown = ({
  item,
  mn = true,
  des = true,
  getDistinctInvoiceCreator = false,
  showCross = false,
  handleClose,
}) => {
  return (
    <>
      <Box
        sx={{
          "&:hover": {
            backgroundColor: "#fff",
          },
        }}
      >
        <Stack direction="row" spacing={1} alignItems={"center"}>
          {/* staff profile pic */}
          <Stack direction="column">
            <img
              src={item?.profilePic ?? imagePlaceHolder}
              alt="Staff"
              style={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />
          </Stack>
          {/* Staff details */}
          <Stack direction="column">
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "10px",
                whiteSpace: "normal",
                overflowWrap: "break-word",
                width: "100%",
              }}
            >
              {getDistinctInvoiceCreator
                ? item?.personName
                : FormatFullName(
                    item?.firstName,
                    item?.middleName,
                    item?.lastName
                  ) ?? item?.name}
            </Typography>
            {mn && item?.mobileNumber && (
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "8px",
                  whiteSpace: "normal",
                  overflowWrap: "break-word",
                  width: "80%",
                }}
              >
                Mob.:- {item?.mobileNumber}
              </Typography>
            )}
            {des && item?.departmentName && item?.designation && (
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "8px",
                  whiteSpace: "normal",
                  // overflowWrap: "break-word",
                  width: "100%",
                  overflowWrap: "anywhere",
                }}
              >
                {item?.designation + "-" + item?.departmentName}
              </Typography>
            )}
          </Stack>
          {showCross && (
            <Stack direction="column" alignItems={"flex-end"}>
              <IconButton
                size="small"
                sx={{
                  color: colors.red[500],
                }}
                onClick={handleClose}
              >
                <Close />
              </IconButton>
            </Stack>
          )}
        </Stack>
      </Box>
    </>
  );
};
