import API_ENDPOINTS from "app/config/endpoints";
import { schoolTokenAxios, authTokenAxios } from "../axios";

export const getAllComplaints = (params) => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.reception.getComplaint}`, {
    params,
  });
};

export const getAllVisitorInfo = () => {
  return schoolTokenAxios.get(API_ENDPOINTS.reception.getVisitorInfo);
};

export const getVisitorInfoLogic = (params) => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.reception.getVisitorInfo}`, {
    params,
  });
};

export const addVisitor = (payload) => {
  return schoolTokenAxios.post(
    `${API_ENDPOINTS.reception.addVisitor}`,
    payload
  );
};

export const exitVisitorLogic = (payload) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.reception.exitVisitor}`,
    payload
  );
};

export const putStaffOnLeave = (payload) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.reception.putStaffOnLeave}`,
    payload
  );
};
export const putStaffOnLeave2 = (payload) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.reception.putStaffOnLeave2}`,
    payload
  );
};

export const updateVisitor = (payload) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.reception.updateVisitorData}`,
    payload
  );
};

export const getClassDropdownLogic = (payload) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.commonApi.getClassDropdown}/${payload.schoolDocId}?session=${payload.selectedSession}&medium=${payload.selectedMedium}`
  );
};
export const getClassDropdownForAdmissionEnquiry = (payload) => {
  let body = {
    session: payload.session,
    medium: payload.medium,
  };
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.commonApi.getClassDropdown}/${payload?.schoolDocId}`,
    {
      params: {
        ...body,
      },
    }
  );
};
export const getClassDropdownWithOnlyClass = (params) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.commonApi.getClassDropdown}/${params.schoolDocId}`,
    {
      params,
    }
  );
};

export const getAllDepartmentLogic = (payload) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.commonApi.getAllDepartment}?session=${payload.selectedSession}&medium=${payload.sessionMedium}`
  );
};

export const getStaffDetailLogic = (payload) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.commonApi.getStaffDetail}?departmentDocId=${payload.departmentId}&session=${payload.selectedSession}&medium=${payload.sessionMedium}`
  );
};

//staff attendance logics
export const getStaffAttendanceByDateLogic = (params) => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.reception.getStaffAttendance}`, {
    params,
  });
};
export const getAllStaffAttendanceByMonth = (params) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.reception.getAllStaffAttendanceByMonth}`,
    {
      params,
    }
  );
};
export const getMonthlyStaffAttendanceBySchool = (params) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.reception.getMonthlyStaffAttendanceBySchool}`,
    {
      params,
    }
  );
};

export const createTodayAttendanceLogic = (payload) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.reception.createTodayAttendance}?session=${payload.selectedSession}&medium=${payload.selectedMedium}`
  );
};
export const markStaffAttendance = (obj) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.reception.markStaffAttendance}?attendanceDocId=${obj.attendanceDocId}`
  );
};

export const markHolidayLogic = (payload) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.reception.markHoliday}`,
    payload
  );
};

export const updateStaffAttendanceLogic = (payload) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.reception.updateStaffAttendance}`,
    {
      ...payload,
    }
  );
};

export const deleteComplaint = (complaintId) => {
  return schoolTokenAxios.delete(
    `${API_ENDPOINTS.reception.deleteComplaint}/${complaintId}`
  );
};

export const updateAssignee = (payload) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.reception.updateAssignee}`,
    payload
  );
};

export const addNewComplaintByStaff = (payload) => {
  return schoolTokenAxios.post(
    `${API_ENDPOINTS.reception.addNewComplaintByStaff}`,
    payload
  );
};

export const getComplaintDetailByComplaintId = (complaintId) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.reception.getComplaint}?complaintDocId=${complaintId}`
  );
};

export const resolveComplaint = (payload) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.reception.resolveComplaint}`,
    payload
  );
};
export const getStudents1 = (params) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.studentManagement.getStudents1}`,
    {
      params: {
        ...params,
      },
    }
  );
};
