import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import CommonTable1 from "app/components/Tables/CommonTable1";
import CustomLoader from "app/components/common/CustomLoader";
import ExportButtonGroup from "app/components/common/ExportButtonGroup";
import TableActionButtons from "app/components/common/TableActionButtons";
import {
  selectActiveSession,
  selectedMediumSelector,
} from "app/modules/schools/selectors";
import * as XLSX from "xlsx";

import { getSummarizedFeeStructureGroup } from "app/services/schoolService/newFee.service";
import React, { useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import AddGroupFeeStructureDialog from "./AddGroupFeeStructureDialog";
import ViewGroupedFeeStructure from "./ViewGroupedFeeStructure";
import DialogHeader from "app/components/common/DialogHeader";
import { YouTube } from "@mui/icons-material";
import YoutubeVideoDialog from "app/components/common/Dialogs/YoutubeVideoDialog";

const SummarizedView = () => {
  const heightRef = useRef(null);
  const selectedSession = useSelector(selectActiveSession);
  const selectedMedium = useSelector(selectedMediumSelector);
  const [openYoutubeDialog, setOpenYoutubeDialog] = useState(false);

  const [state, setState] = useState({
    viewDialog: false,
    classDocId: "",
    dialog: false,
  });
  const columns = useMemo(
    () => [
      {
        Cell: ({ cell }) =>
          Number(cell.row.id) + 1 < 10
            ? `0${Number(cell.row.id) + 1}`
            : Number(cell.row.id) + 1,
        header: "Sr.No",
        maxSize: 10,
      },
      {
        accessorKey: "className",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <Typography>{`${original?.className}-${original?.stream}`}</Typography>
        ),
        header: "Class-Stream",
        maxSize: 20,
      },
      {
        accessorKey: "sections",
        header: "Sections",
        maxSize: 10,
      },
      {
        accessorKey: "countFeeType",
        header: "Fee Type",
        maxSize: 10,
      },
      {
        accessorKey: "countFeeRelaxation",
        header: "Fee Relaxation",
        maxSize: 10,
      },
      {
        accessorKey: "countFeeStructureGroup",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <Typography>
            <span style={{ color: "green" }}>
              {original?.countFeeStructureGroup ?? 0}
              {" - "}
            </span>
            <span style={{ color: "red" }}>
              {original?.incompleteFeeGroup ?? 0}
            </span>
          </Typography>
        ),
        header: "Fee Group",
        maxSize: 10,
      },
      {
        accessorKey: "countReuestedFeeStructure",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <Typography>
            <span style={{ color: "green" }}>
              {original?.countApprovedFeeStructure ?? 0}
              {" - "}
            </span>
            <span style={{ color: "orange" }}>
              {original?.countReuestedFeeStructure ?? 0}
              {" - "}
            </span>
            <span style={{ color: "red" }}>
              {original?.inCompleteFeeStructure ?? 0}
            </span>
          </Typography>
        ),
        header: "Fee Structure",
        maxSize: 10,
      },

      {
        header: "Action",
        maxSize: 10,
        Cell: ({ cell }) => {
          const { original } = cell.row;
          return (
            <TableActionButtons
              showView
              handleView={() =>
                setState((prev) => ({
                  ...prev,
                  viewDialog: true,
                  classDocId: original?.classDocId,
                }))
              }
            />
          );
        },
      },
    ],
    []
  );
  const {
    isLoading: getSummarizedFeeStructureGroupLoading,
    isFetching: getSummarizedFeeStructureGroupFetching,
    refetch: getSummarizedFeeStructureGroupRefetch,
    data: getSummarizedFeeStructureGroup_List,
  } = useQuery({
    queryKey: ["getSummarizedFeeStructureGroup", selectedSession],
    queryFn: () =>
      getSummarizedFeeStructureGroup({
        session: selectedSession,
        medium: selectedMedium,
      }),
    onSuccess: (success) => {},
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });
  let header = [
    "#",
    "Class_Stream",
    "Total_Sections",
    "Fee_Type_Count",
    "Fee_Relaxation_Count",
    "Fee_Structure_Group_Count",
    "Fee_Structure_Requested_Count",
    "To_Be_Fee_Structure",
  ];
  const [selectedOptions, setSelectedOptions] = useState(header);
  const [openCheckBox, setOpenCheckBox] = useState(false);

  let exportArray = getSummarizedFeeStructureGroup_List?.data?.list?.map(
    (item, index) => {
      return {
        "#": index < 9 ? `0${index + 1}` : index + 1,
        Class_Stream: `${item?.className}-${item?.stream}`,
        Total_Sections: item?.sections,
        Fee_Type_Count: item?.countFeeType,
        Fee_Relaxation_Count: item?.countFeeRelaxation,
        Fee_Structure_Group_Count: item?.countFeeStructureGroup,
        To_Be_Fee_Structure: item?.toBeFeeStructure,
        Fee_Structure_Requested_Count: item?.countReuestedFeeStructure,
        Approved_Fee_Structure: item?.countApprovedFeeStructure,
        In_Completed: item?.inCompleteFeeStructure,
      };
    }
  );

  const handleExport = () => {
    const filteredExportArray = exportArray?.map((item) => {
      const filteredItem = {};
      selectedOptions.forEach((option) => {
        filteredItem[option] = item[option];
      });
      return filteredItem;
    });

    const worksheet = XLSX.utils.json_to_sheet(filteredExportArray);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      "Summarized Fee Structure"
    );
    XLSX.writeFile(workbook, "SummarizedFeeStructure.xlsx");
  };
  const handleCheckboxChange = (option, isChecked) => {
    if (isChecked) {
      setSelectedOptions((prevSelectedOptions) => [
        ...prevSelectedOptions,
        option,
      ]);
    } else {
      setSelectedOptions((prevSelectedOptions) =>
        prevSelectedOptions.filter(
          (selectedOption) => selectedOption !== option
        )
      );
    }
  };

  const videoLink = [
    {
      link: "https://www.youtube.com/embed/DL4AST7FAHo?si=dppmh_6jDjQNRPVc",
      title: "How to create Academic Fee Structure",
    },
  ];

  return (
    <>
      <CustomLoader
        show={
          getSummarizedFeeStructureGroupLoading ||
          getSummarizedFeeStructureGroupFetching
        }
      />

      {/* <YoutubeVideoDialog
        open={openYoutubeDialog}
        handleCloseDialog={() => setOpenYoutubeDialog(false)}
        videoLink={videoLink}
      /> */}
      {/* Dialog for selecting options for export*/}
      <Dialog
        open={openCheckBox}
        onClose={() => setOpenCheckBox(false)}
        PaperProps={{
          sx: { minWidth: "400px" },
        }}
      >
        <DialogHeader
          title="Select Information to show in the excel file"
          handleClose={() => setOpenCheckBox(false)}
        />
        <DialogContent>
          <Box>
            {header.map((option) => (
              <Stack direction="row">
                <FormControlLabel
                  key={option}
                  control={
                    <Checkbox
                      checked={selectedOptions.includes(option)}
                      onChange={(e) =>
                        handleCheckboxChange(option, e.target.checked)
                      }
                    />
                  }
                  label={option}
                />
              </Stack>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ mb: 1 }}
            onClick={() => {
              setSelectedOptions(header);
              handleExport();
              setOpenCheckBox(false);
            }}
          >
            DownLoad
          </Button>
        </DialogActions>
      </Dialog>
      <ViewGroupedFeeStructure
        classDocId={state.classDocId}
        handleClose={() =>
          setState((prev) => ({ ...prev, viewDialog: false, classDocId: "" }))
        }
        open={state.viewDialog}
        refetch={() => getSummarizedFeeStructureGroupRefetch()}
      />
      <AddGroupFeeStructureDialog
        refetch={() => getSummarizedFeeStructureGroupRefetch()}
        showDialog={state.dialog}
        onClose={() => setState((prev) => ({ ...prev, dialog: false }))}
      />
      <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          gap={2}
          justifyContent={"flex-end"}
          py={1.5}
        >
          <Button
            onClick={() => setState((prev) => ({ ...prev, dialog: true }))}
            size="large"
            variant="outlined"
            color="secondary"
            sx={{ backgroundColor: "#fff", py: 1, borderRadius: "10px" }}
          >
            + New Fee Structure Group
          </Button>
          <Box sx={{ mt: "-10px" }}>
            <Stack direction="row" gap={1} alignItems="center">
              <ExportButtonGroup
                handleClickExcel={() => setOpenCheckBox(true)}
                showPdf={false}
              />
              {/* <YouTube
                sx={{
                  color: "#FF0000",
                  fontSize: "2.5rem",
                  cursor: "pointer",
                  mt: 2,
                }}
                onClick={() => setOpenYoutubeDialog(true)}
              /> */}
            </Stack>
          </Box>
        </Stack>
        <Box sx={{ height: "90%" }} ref={heightRef}>
          <CommonTable1
            columns={columns}
            data={getSummarizedFeeStructureGroup_List?.data?.list}
            maxHeight={`${heightRef?.current?.clientHeight - 70}px`}
          />
        </Box>
      </Box>
    </>
  );
};

export default SummarizedView;
