import { Edit } from "@mui/icons-material";
import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import TableActionButtons from "./TableActionButtons";

const LabelTextComponent = ({
  label,
  text,
  flex = 1,
  edit = false,
  handleEdit = () => {},
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <Box sx={{ flex }}>
      <Stack>
        <Typography
          fontSize={isMobile ? 12 : 14}
          color="primary"
          sx={{ opacity: 0.75 }}
        >
          {label ?? "Label"}
        </Typography>
        <Stack direction="row" alignItems="center">
          <Typography
            fontSize={isMobile ? 14 : 16}
            fontWeight={600}
            color="primary"
            sx={{ overflowWrap: "anywhere" }}
          >
            {text && text !== "undefined" && text !== undefined
              ? String(text).replace(/undefined/g, "")
              : "--"}
          </Typography>
          {edit && (
            <TableActionButtons handleEdit={handleEdit} showEdit={edit} />
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default LabelTextComponent;
