import { YouTube } from "@mui/icons-material";
import { Fab } from "@mui/material";
import React from "react";
import YoutubeVideoDialog from "../Dialogs/YoutubeVideoDialog";

const YouTubeFloating = ({ position = "bottom", videoLink }) => {
  const [openYoutubeDialog, setOpenYoutubeDialog] = React.useState(false);
  const [ShowFloatingButton, setShowFloatingButton] = React.useState(true);

  return (
    <>
      <YoutubeVideoDialog
        open={openYoutubeDialog}
        handleCloseDialog={() => setOpenYoutubeDialog(false)}
        videoLink={videoLink}
      />

      {ShowFloatingButton && (
        <Fab
          aria-label="add"
          sx={{
            position: "fixed",
            bottom: position === "bottom" ? "5%" : "auto",
            top: position === "top" ? "10%" : "auto",
            right: "1%",
            color: "red",
            backgroundColor: "white",
          }}
          onClick={() => {
            setOpenYoutubeDialog(true);
          }}
        >
          <YouTube style={{ height: 100, width: 40 }} />
        </Fab>
      )}
    </>
  );
};

export default YouTubeFloating;
