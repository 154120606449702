import { Tooltip, Typography, useMediaQuery } from "@mui/material";
import React from "react";

const TextFieldLabel = ({
  title,
  styleProps,
  superScript,
  showSuperScript = false,
  mb = 0.4,
  required = false,
  ...props
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <Typography
      sx={{
        fontWeight: 600,
        fontSize: isMobile ? "12px" : "14px",
        color: (theme) => theme.palette.primary.main,
        opacity: 0.75,
        mb,
        ...styleProps,
      }}
      {...props}
    >
      {title}

      {showSuperScript && (
        <sup
          style={{
            color: "green",
            top: "-0.5em",
          }}
        >
          ({superScript})
        </sup>
      )}
      {required && <span style={{ color: "red" }}>*</span>}
    </Typography>
  );
};

export default TextFieldLabel;
