import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Button,
  DialogContent,
  Grid,
  IconButton,
  Stack,
  styled,
  Tab,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import CommonDialog from "app/components/common/Dialogs/CommonDialog";
import AddNewThreadDialog from "app/components/common/Dialogs/reception/AddNewThreadDialog";
import PaymentModeComponent from "app/components/common/PaymentModeComponent";

import TextFieldLabel from "app/components/common/TextFieldLabel";
import { IndianCurrency } from "app/utils/helper";
import {
  CustomTextField,
  TabsWrapper,
} from "assets/styles/globalStyledComponent";
import dayjs from "dayjs";
import React, { useState } from "react";

const ViewEnquiryForm = ({ open, onClose }) => {
  const [state, setState] = useState({
    tabValue: "basicInfo",
    depositorName: "",
    collectionDate: new Date(),
    addThread: false,
    editThread: false,
    editThreadData: {},
  });
  const handleCloseDialog = () => {
    onClose();
  };

  return (
    <>
      <AddNewThreadDialog
        open={state.addThread}
        onClose={() => setState((prev) => ({ ...prev, addThread: false }))}
      />
      <CommonDialog
        open={open}
        onClose={handleCloseDialog}
        title="View Enquiry"
        minWidth="800px"
      >
        <DialogContent>
          <Wrapper>
            <TabContext value={state.tabValue}>
              <TabsWrapper>
                <TabList
                  onChange={(e, newValue) =>
                    setState((prev) => ({ ...prev, tabValue: newValue }))
                  }
                >
                  <Tab label="Basic Info" value="basicInfo" />
                  <Tab label="Follows Ups" value="followUps" />
                  <Tab label="Payment Info" value="paymentInfo" />
                </TabList>
              </TabsWrapper>
              {/* basic info panel */}
              <TabPanel value="basicInfo" sx={{ p: "0 !important" }}>
                <Typography className="big_title">
                  Enquiry Form Information
                </Typography>
                <div className="back_wrapper">
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography className="label">School Name</Typography>
                      <Typography className="value">--</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography className="label">Class Stream</Typography>
                      <Typography className="value">--</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography className="label">Enquiry Date</Typography>
                      <Typography className="value">--</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography className="label">Form Number</Typography>
                      <Typography className="value">--</Typography>
                    </Grid>
                  </Grid>
                </div>
                <Typography className="big_title">
                  Student Information
                </Typography>
                <div className="back_wrapper">
                  <Stack direction={"row"}>
                    <Box sx={{ width: "200px", height: "200px" }}></Box>
                    <Box flexGrow={1}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={3}>
                          <Typography className="label">
                            Student Name
                          </Typography>
                          <Typography className="value">--</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <Typography className="label">Gender</Typography>
                          <Typography className="value">--</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <Typography className="label">Community</Typography>
                          <Typography className="value">--</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <Typography className="label">Category</Typography>
                          <Typography className="value">--</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <Typography className="label">Category</Typography>
                          <Typography className="value">--</Typography>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                          <Typography className="label">
                            Primary Mobile Number
                          </Typography>
                          <Typography className="value">--</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <Typography className="label">
                            WhatsApp Number
                          </Typography>
                          <Typography className="value">--</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <Typography className="label">Email</Typography>
                          <Typography className="value">--</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <Typography className="label">
                            Aadhar Number
                          </Typography>
                          <Typography className="value">--</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <Typography className="label">Living Type</Typography>
                          <Typography className="value">--</Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Stack>
                </div>

                <Typography className="big_title">Sibling Details</Typography>
                <div className="back_wrapper">
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography className="label">Is Sibling ?</Typography>
                      <Typography className="value">--</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography className="label">Admssion Number</Typography>
                      <Typography className="value">--</Typography>
                    </Grid>
                  </Grid>
                </div>

                <Typography className="big_title">
                  Parent's Information
                </Typography>
                <div className="back_wrapper">
                  <Typography className="mid_title">Mother Info</Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography className="label">Full Name</Typography>
                      <Typography className="value">--</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography className="label">Mobile Number</Typography>
                      <Typography className="value">--</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography className="label">Occupation</Typography>
                      <Typography className="value">--</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography className="label">Annual Income</Typography>
                      <Typography className="value">--</Typography>
                    </Grid>
                  </Grid>
                  <Typography className="mid_title">Father Info</Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography className="label">Full Name</Typography>
                      <Typography className="value">--</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography className="label">Mobile Number</Typography>
                      <Typography className="value">--</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography className="label">Occupation</Typography>
                      <Typography className="value">--</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography className="label">Annual Income</Typography>
                      <Typography className="value">--</Typography>
                    </Grid>
                  </Grid>
                  <Typography className="mid_title">Guardian Info</Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography className="label">Full Name</Typography>
                      <Typography className="value">--</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography className="label">Mobile Number</Typography>
                      <Typography className="value">--</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography className="label">Occupation</Typography>
                      <Typography className="value">--</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography className="label">Relation</Typography>
                      <Typography className="value">--</Typography>
                    </Grid>
                  </Grid>
                </div>
                <Typography className="big_title">
                  Address Information
                </Typography>
                <div className="back_wrapper">
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <Typography className="label">Address</Typography>
                      <Typography className="value">--</Typography>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Typography className="label">Pin Code</Typography>
                      <Typography className="value">--</Typography>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Typography className="label">District</Typography>
                      <Typography className="value">--</Typography>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Typography className="label">State</Typography>
                      <Typography className="value">--</Typography>
                    </Grid>
                  </Grid>
                </div>
                <Typography className="big_title">
                  Previous School Info
                </Typography>
                <div className="back_wrapper">
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={4}>
                      <Typography className="label">School Name</Typography>
                      <Typography className="value">--</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography className="label">Current Class</Typography>
                      <Typography className="value">--</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography className="label">Probability</Typography>
                      <Typography className="value">--</Typography>
                    </Grid>
                  </Grid>
                </div>
              </TabPanel>
              {/* follow up panel */}
              <TabPanel value="followUps" sx={{ p: "0" }}>
                <Typography className="big_title">
                  Enquiry Information
                </Typography>
                <div className="back_wrapper">
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography className="label">Enquiry Date</Typography>
                      <Typography className="value">--</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography className="label">
                        Last Follow Up Date
                      </Typography>
                      <Typography className="value">--</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography className="label">
                        Next Follow Up Date
                      </Typography>
                      <Typography className="value">--</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography className="label">Source</Typography>
                      <Typography className="value">--</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography className="label">Reference By</Typography>
                      <Typography className="value">--</Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <Typography className="label">
                        Reference Mobile Number
                      </Typography>
                      <Typography className="value">--</Typography>
                    </Grid>
                  </Grid>
                </div>
                <Typography className="big_title">
                  Discusion Threads:
                </Typography>
                <div className="back_wrapper_thread">
                  <div className="thread_wrap">
                    <div className="date_wrap">
                      <div>
                        <Typography className="label">
                          Ankit Jaiswal <span className="date_on">on </span>
                          {dayjs().format("DD MMM, YYYY")}
                        </Typography>
                      </div>
                      {/* icons */}
                      <div>
                        <IconButton>
                          <img
                            alt="delete"
                            src="/delete-icon.png"
                            className="icon_image"
                          />
                        </IconButton>
                        <IconButton>
                          <img
                            alt="delete"
                            src="/edit-icon.png"
                            className="icon_image"
                          />
                        </IconButton>
                      </div>
                    </div>
                    <Grid container spacing={1} p={1}>
                      <Grid item xs={12} sm={4}>
                        <Typography className="label">Enquiry Date</Typography>
                        <Typography className="value">
                          {dayjs().format("DD MMM, YYYY")}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <Typography className="label">
                          What Discussed ?
                        </Typography>
                        <Typography className="value">--</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography className="label">
                          Next FollowUp Date
                        </Typography>
                        <Typography className="value">
                          {dayjs().format("DD MMM, YYYY")}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <Typography className="label">
                          What To Be Discussed ?
                        </Typography>
                        <Typography className="value">--</Typography>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <Button
                  color="secondary"
                  sx={{ mt: 1 }}
                  onClick={() =>
                    setState((prev) => ({ ...prev, addThread: true }))
                  }
                >
                  +Add More Thread
                </Button>
              </TabPanel>
              {/* payment panel */}
              <TabPanel value="paymentInfo" sx={{ p: "0" }}>
                <div className="payment_wrap">
                  <Grid
                    container
                    spacing={1}
                    className="header"
                    columns={10}
                    m={0}
                    p={0}
                  >
                    <Grid item xs={2}>
                      <Typography className="value">Form Fee Amt</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography className="value">T Discount</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography className="value">Paying Now</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography className="value">Remarks</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography className="value">Status</Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={1}
                    className="content"
                    alignItems={"center"}
                    columns={10}
                    m={0}
                    p={0}
                    mt={1}
                  >
                    <Grid item xs={2}>
                      <Typography>{IndianCurrency(10000)}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography className="value">
                        <input
                          type="number"
                          placeholder="amount"
                          className="payment_input"
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography className="value">
                        <input
                          type="number"
                          placeholder="amount"
                          className="payment_input"
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography className="value">
                        <input
                          type="text"
                          placeholder="remark"
                          className="payment_input"
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography className="value">Not Paid</Typography>
                    </Grid>
                  </Grid>
                </div>
                <Grid container spacing={1} my={2}>
                  <Grid item xs={12} sm={4}>
                    <TextFieldLabel title={"Depositor's Name"} />
                    <CustomTextField
                      fullWidth
                      size="small"
                      placeholder="Type depositor name"
                      value={state.depositorName}
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          depositorName: e.target.value,
                        }))
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextFieldLabel title={"Collection Date"} />
                    <DatePicker
                      inputFormat="DD-MM-YYYY"
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          collectionDate: e,
                        }))
                      }
                      value={state.collectionDate}
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          size="small"
                          fullWidth
                          error={!state.collectionDate}
                          helperText={
                            !state.collectionDate && "Collection Date required"
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Box>
                  <PaymentModeComponent />
                </Box>
              </TabPanel>
            </TabContext>
          </Wrapper>
        </DialogContent>
      </CommonDialog>
    </>
  );
};

export default ViewEnquiryForm;
const Wrapper = styled("div")`
  .back_wrapper {
    border: 1px solid #3d398933;
    background-color: #fbfbff;
    padding: 10px;
    border-radius: 5px;
  }
  .back_wrapper_thread {
    border: 1px solid #3d398933;
    background-color: #fbfbff;
    padding: 10px;
    border-radius: 5px;
    max-height: 300px;
    overflow-y: auto;
  }
  .mid_title {
    font-size: 16px;
    font-weight: 600;
    margin: 5px 0;
  }
  .big_title {
    font-size: 18px;
    font-weight: 700;
    margin-top: 15px;
    margin-bottom: 7px;
  }
  .label {
    font-size: 14px;
    font-weight: 500;
  }
  .value {
    font-size: 14px;
    font-weight: 600;
  }
  .thread_wrap {
    background-color: #fff;
    border: 1px solid #d0d0e2;
    border-radius: 8px;
    .date_wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 10px;
      border-bottom: 1px solid #d0d0e2;
    }
    .date_on {
      color: #2789fd;
    }
    .icon_image {
      height: 20px;
      object-fit: contain;
    }
  }
  .payment_wrap {
    background-color: #f6f8fc;
    border-radius: 5px;
    border: 1px solid #b6c8d6;
    margin-top: 15px;
    max-height: 300px;
    overflow-y: auto;
    .header {
      border-bottom: 1px solid #b6c8d6;
    }
    .content {
      margin-top: 5px;
      margin-bottom: 5px;
    }
    .payment_input {
      display: block;
      border: 0.3px solid #8ea4b5;
      outline: none;
      width: 90%;
      padding: 2px 4px;
      border-radius: 5px;
      height: 30px;
    }
  }
`;
