import { schoolTokenAxios } from "../axios";

const SCHOOL_BASE = process.env.REACT_APP_SCHOOL_BASE;

let newFee_url = `${SCHOOL_BASE}/newFee`;

export const getFeeRelaxationCategoryByStudent = (params) =>
  schoolTokenAxios.get(`${newFee_url}/getFeeRelaxationCategoryByStudent`, {
    params,
  });

export const addFeeRelaxationCategory = (payload) => {
  return schoolTokenAxios.post(
    `${newFee_url}/addFeeRelaxationCategory`,
    payload
  );
};
export const refreshAcademicFee = (payload) => {
  return schoolTokenAxios.put(`${newFee_url}/refreshAcademicFee`, payload);
};
export const refreshAppliedFeeStructure = (payload) => {
  return schoolTokenAxios.put(
    `${newFee_url}/refreshAppliedFeeStructure`,
    payload
  );
};

export const getFeeRelaxationCategory = (params) => {
  return schoolTokenAxios.get(`${newFee_url}/getFeeRelaxationCategory`, {
    params,
  });
};
export const getClasswiseDuesReportSummary = (params) => {
  return schoolTokenAxios.get(`${newFee_url}/getClasswiseDuesReportSummary`, {
    params,
  });
};
export const getStudentByFeeStructure = (params) => {
  return schoolTokenAxios.get(`${newFee_url}/getStudentByFeeStructure`, {
    params,
  });
};
export const getDistinctFeeType = (params) => {
  return schoolTokenAxios.get(`${newFee_url}/getDistinctFeeType`, {
    params,
  });
};
export const getAppliedFeeStructure = (params) => {
  return schoolTokenAxios.get(`${newFee_url}/getAppliedFeeStructure`, {
    params,
  });
};

export const deleteFeeRelaxationCategory = (id) => {
  return schoolTokenAxios.delete(
    `${newFee_url}/deleteFeeRelaxationCategory/${id}`
  );
};

export const addFeeType = (payload) => {
  return schoolTokenAxios.post(`${newFee_url}/addFeeType`, payload);
};
export const getFeeSummaryByClass = (params) => {
  return schoolTokenAxios.get(`${newFee_url}/getFeeSummaryByClass`, { params });
};
export const applyOptionalFee = (body) => {
  return schoolTokenAxios.put(`${newFee_url}/applyOptionalFee`, body);
};
export const getFeeSummaryByFeeType = (params) => {
  return schoolTokenAxios.get(`${newFee_url}/getFeeSummaryByFeeType`, {
    params,
  });
};

export const getFeeType = (params) => {
  return schoolTokenAxios.get(`${newFee_url}/getFeeType`, {
    params,
  });
};
export const getDistinctInvoiceCreator = (params) => {
  return schoolTokenAxios.get(`${newFee_url}/getDistinctInvoiceCreator`, {
    params,
  });
};

export const getFeeTypeForMultipleClass = (params) => {
  return schoolTokenAxios.get(`${newFee_url}/getFeeTypeForMultipleClass`, {
    params,
  });
};

export const deleteFeeType = (payload) => {
  return schoolTokenAxios.put(`${newFee_url}/deleteFeeType`, payload);
};

export const addFeeStructureGroup = (payload) => {
  return schoolTokenAxios.post(`${newFee_url}/addFeeStructureGroup`, payload);
};
export const updateRecurringDiscountAmount = (payload) => {
  return schoolTokenAxios.put(
    `${newFee_url}/updateRecurringDiscountAmount`,
    payload
  );
};
export const updateRecurringDiscountInTransport = (payload) => {
  return schoolTokenAxios.put(
    `${newFee_url}/updateRecurringDiscountInTransport`,
    payload
  );
};

export const getFeeStructureGroup = (params) => {
  return schoolTokenAxios.get(`${newFee_url}/getFeeStructureGroup`, {
    params,
  });
};
export const getArrears = (params) => {
  return schoolTokenAxios.get(`${newFee_url}/getArrears`, {
    params,
  });
};

export const deleteFeeStructureGroup = (id) => {
  return schoolTokenAxios.delete(`${newFee_url}/deleteFeeStructureGroup/${id}`);
};

export const getAllFeeStructure = (params) => {
  return schoolTokenAxios.get(`${newFee_url}/getAllFeeStructure`, {
    params,
  });
};

export const getFeeStructureForStudentRegistration = (params) => {
  return schoolTokenAxios.get(
    `${newFee_url}/getFeeStructureForStudentRegistration`,
    {
      params,
    }
  );
};

export const updateFeeStructureInGroup = (payload) => {
  return schoolTokenAxios.put(
    `${newFee_url}/updateFeeStructureInGroup`,
    payload
  );
};

export const rejectFeeStructure = (id) => {
  return schoolTokenAxios.get(`${newFee_url}/rejectFeeStructure/${id}`);
};

export const approveFeeStructure = (id) => {
  return schoolTokenAxios.get(`${newFee_url}/approveFeeStructure/${id}`);
};

export const deleteFeeStructure = (id) => {
  return schoolTokenAxios.delete(`${newFee_url}/deleteFeeStructure/${id}`);
};

export const linkFeeStructureWithGroup = (payload) => {
  return schoolTokenAxios.put(
    `${newFee_url}/linkFeeStructureWithGroup`,
    payload
  );
};

export const updateFineRate = (payload) => {
  return schoolTokenAxios.put(`${newFee_url}/updateFineRate`, payload);
};
export const getFineRate = (params) => {
  return schoolTokenAxios.get(`${newFee_url}/getFineRate`, { params });
};

export const getDuesTillDateByClass = (params) => {
  return schoolTokenAxios.get(`${newFee_url}/getDuesTillDateByClass`, {
    params,
  });
};

export const getDueSlipTillDateByClass = (params) => {
  return schoolTokenAxios.get(`${newFee_url}/getDueSlipTillDateByClass`, {
    params,
  });
};

export const getFeeCollectionForStudentProfile = (params) => {
  return schoolTokenAxios.get(
    `${newFee_url}/getFeeCollectionForStudentProfile`,
    {
      params,
    }
  );
};

export const getFeeCollectionForPay = (params) => {
  return schoolTokenAxios.get(`${newFee_url}/getFeeCollectionForPay`, {
    params,
  });
};

export const collectFee = (payload) => {
  return schoolTokenAxios.put(`${newFee_url}/collectFee`, payload);
};

export const deleteFeeCard = (payload) => {
  return schoolTokenAxios.put(`${newFee_url}/deleteFeeCard`, payload);
};

export const getFeeInvoice = (params) => {
  return schoolTokenAxios.get(`${newFee_url}/getFeeInvoice`, {
    params,
  });
};

export const getAggregatedFeeInvoice = (params) => {
  return schoolTokenAxios.get(`${newFee_url}/getAggregatedFeeInvoice`, {
    params,
  });
};

export const getFeeInvoiceTemplate2 = (params) => {
  return schoolTokenAxios.get(`${newFee_url}/getFeeInvoiceTemplate2`, {
    params,
  });
};
export const getFeeInvoiceSummary = (params) => {
  return schoolTokenAxios.get(`${newFee_url}/getFeeInvoiceSummary`, {
    params,
  });
};
export const getClasswiseFeeSummary = (params) => {
  return schoolTokenAxios.get(`${newFee_url}/getClasswiseFeeSummary`, {
    params,
  });
};
export const getSummarizedFeeStructureGroup = (params) => {
  return schoolTokenAxios.get(`${newFee_url}/getSummarizedFeeStructureGroup`, {
    params,
  });
};

export const changeFeeInvoiceStatus = (payload) => {
  return schoolTokenAxios.put(`${newFee_url}/changeFeeInvoiceStatus`, payload);
};
export const updateFeeRemarksInStudentAcademicInfo = (payload) => {
  return schoolTokenAxios.put(
    `${newFee_url}/updateFeeRemarksInStudentAcademicInfo`,
    payload
  );
};

export const deleteFeeInvoice = (payload) => {
  return schoolTokenAxios.put(`${newFee_url}/deleteFeeInvoice`, payload);
};

export const getTransportDueList = (params) => {
  return schoolTokenAxios.get(`${newFee_url}/getTransportDueList`, {
    params,
  });
};

/****************************************Certificate Service******************************************************/
export const getBonafideDetail = (params) => {
  return schoolTokenAxios.get(`${newFee_url}/getBonafideDetail`, {
    params,
  });
};
